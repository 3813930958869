import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, updateProfile, signOut } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc, collection, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your Firebase config
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyBZc3yB2NLOtylXnbMEALvPFpJAJnxadiU",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "staplestreetstyles.firebaseapp.com",
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "staplestreetstyles",
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "staplestreetstyles.appspot.com",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "7461710724",
    appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:7461710724:web:0ed84d5daa00dbefc88bd1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Google Auth
const googleProvider = new GoogleAuthProvider();

// Sign out function
const signOutUser = async () => {
    try {
        await signOut(auth);
        console.log("User signed out successfully");
    } catch (error) {
        console.error("Error signing out: ", error);
    }
};

// Add product function
const addProduct = async (productData) => {
    try {
        const productRef = collection(db, 'products');
        await addDoc(productRef, productData);
        console.log('Product added to Firestore:', productData);
    } catch (error) {
        console.error('Error adding product: ', error);
    }
};

// Edit product function
const editProduct = async (productId, productData) => {
    try {
        const productRef = doc(db, 'products', productId);
        await updateDoc(productRef, productData);
        console.log('Product updated in Firestore:', productData);
    } catch (error) {
        console.error('Error updating product: ', error);
    }
};

// Delete product function
const deleteProduct = async (productId) => {
    try {
        const productRef = doc(db, 'products', productId);
        await deleteDoc(productRef);
        console.log('Product deleted from Firestore:', productId);
    } catch (error) {
        console.error('Error deleting product: ', error);
    }
};

// Export Firebase services and product-related functions
export {
    app,
    auth,
    db,
    storage,
    googleProvider,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    updateProfile,
    setDoc,
    doc,
    getDoc,
    signOutUser,
    addProduct,    // New add product function
    editProduct,   // New edit product function
    deleteProduct  // New delete product function
};