import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import { FaUser, FaShoppingBag } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoMdMenu } from "react-icons/io";
import { ShopContext } from '../../context/ShopContext';
import { useAuth } from '../../context/AuthContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import { useTheme } from '../../context/ThemeContext'; // Import the useTheme hook
import SearchBar from './SearchBar';
import logo from '../../assets/logo.png';

const Navbar = () => {
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const { currentUser, isLoggedIn, admin, logout } = useAuth();
    const { cartItems, setCartItems } = useContext(ShopContext);
    const [cartCount, setCartCount] = useState(0);
    const [avatar, setAvatar] = useState(null);
    const { theme } = useTheme(); // Get the active theme from ThemeContext
    const defaultAvatar = "https://via.placeholder.com/40";

    // Real-time listener for the cart data
    useEffect(() => {
        if (currentUser) {
            const userCartRef = doc(db, 'customers', currentUser.uid);
            const unsubscribe = onSnapshot(userCartRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const cartData = docSnapshot.data().cart || [];
                    setCartItems(cartData);
                    setCartCount(cartData.length);
                }
            });
            return () => unsubscribe();
        }
    }, [currentUser, setCartItems]);

    // Fetch user profile from the AuthContext and update avatar
    useEffect(() => {
        if (currentUser) {
            setAvatar(currentUser.photoURL || defaultAvatar);
        } else {
            setAvatar(defaultAvatar);
        }
    }, [currentUser]);

    // Handle logout
    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
        } catch (error) {
            console.error("Error logging out: ", error);
        }
    };

    return (
        <div className={`flex items-center justify-between py-5 font-medium transition-all`} style={{ color: theme.text }}>
            {/* Logo */}
            <button onClick={() => navigate('/')}>
                {/* Conditional rendering of the logo based on the current theme */}
                <img src={logo} alt="Logo" className="h-12" />
            </button>

            {/* Navigation Links */}
            <ul className="hidden sm:flex gap-5 text-sm">
                <NavLink to='/' className="flex flex-col items-center gap-1" style={{ color: theme.text }}>
                    <p>HOME</p>
                    <hr className="w-2/4 border-none h-[1.5px] hidden" style={{ backgroundColor: theme.text }} />
                </NavLink>
                <NavLink to='/collection' className="flex flex-col items-center gap-1" style={{ color: theme.text }}>
                    <p>COLLECTION</p>
                    <hr className="w-2/4 border-none h-[1.5px] hidden" style={{ backgroundColor: theme.text }} />
                </NavLink>
                {/* <NavLink to='/bundles' className="flex flex-col items-center gap-1" style={{ color: theme.text }}>
                    <p>BUNDLES</p>
                    <hr className="w-2/4 border-none h-[1.5px] hidden" style={{ backgroundColor: theme.text }} />
                </NavLink> */}
                <NavLink to='/about' className="flex flex-col items-center gap-1" style={{ color: theme.text }}>
                    <p>ABOUT</p>
                    <hr className="w-2/4 border-none h-[1.5px] hidden" style={{ backgroundColor: theme.text }} />
                </NavLink>
                <NavLink to='/contact' className="flex flex-col items-center gap-1" style={{ color: theme.text }}>
                    <p>CONTACT</p>
                    <hr className="w-2/4 border-none h-[1.5px] hidden" style={{ backgroundColor: theme.text }} />
                </NavLink>
            </ul>

            {/* Right Side Icons and Dropdown */}
            <div className='flex items-center gap-6'>
                {/* Search Icon */}
                <SearchBar />

                {/* Cart Icon */}
                <Link
                    to='/cart'
                    className="relative btn btn-ghost btn-circle"
                    style={{ backgroundColorHover: theme.primary }}
                >
                    <FaShoppingBag
                        className="text-2xl cursor-pointer"
                        style={{ color: theme.text }}
                    />
                    <span className='absolute right-[-1px] bottom-[2px] w-4 text-center leading-4 bg-[#ff007f] text-white aspect-square rounded-full text-[8px]'>
                        {cartCount} {/* Show the real-time cart count */}
                    </span>
                </Link>

                {/* User Avatar and Dropdown */}
                <div className="dropdown dropdown-end -ml-2">
                    <div tabIndex={0} role="button">
                        {isLoggedIn ? (
                            <div className="w-10 h-10 rounded-full overflow-hidden">
                                <img
                                    alt="User Avatar"
                                    src={avatar}
                                    onError={(e) => e.target.src = defaultAvatar}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        ) : (
                            <div className='btn btn-ghost btn-circle hover:bg-gray-200'>
                                <FaUser className="text-2xl" style={{ color: theme.text }} />
                            </div>
                        )}
                    </div>
                    <ul
                        tabIndex={0}
                        className="menu menu-sm dropdown-content rounded-box z-[1] mt-3 w-52 p-2 shadow"
                        style={{ backgroundColor: theme.primary, color: theme.text }}
                    >
                        {/* Not Logged In */}
                        {!isLoggedIn && (
                            <>
                                <li><button onClick={() => navigate('/auth/login')}>Login</button></li>
                                <li><button onClick={() => navigate('/settings')}>Settings</button></li>
                            </>
                        )}

                        {/* Logged In Regular User */}
                        {isLoggedIn && !admin && (
                            <>
                                {/* <li><button onClick={() => navigate('/dashboard')}>Dashboard</button></li> */}
                                <li><button onClick={() => navigate('/orders')}>Orders</button></li>
                                <li><button onClick={() => navigate('/settings')}>Settings</button></li>
                                <li><button onClick={handleLogout}>Logout</button></li>
                            </>
                        )}

                        {/* Logged In Admin */}
                        {isLoggedIn && admin && (
                            <>
                                {/* <li><button onClick={() => navigate('/dashboard')}>Dashboard</button></li> */}
                                <li><button onClick={() => navigate('/orders')}>Orders</button></li>
                                <li><button onClick={() => navigate('/settings')}>Settings</button></li>
                                <li><button onClick={() => navigate('/admin')}>Admin Panel</button></li>
                                <li><button onClick={handleLogout}>Logout</button></li>
                            </>
                        )}
                    </ul>
                </div>

                {/* Mobile Menu Icon */}
                <IoMdMenu
                    onClick={() => setVisible(true)}
                    className="text-3xl cursor-pointer sm:hidden p-2 rounded-full"
                    style={{ color: theme.text, backgroundColor: theme.primary }}
                />
            </div>

            {/* Mobile Sidebar Menu */}
            <div
                className={`fixed top-0 right-0 bottom-0 transition-all sm:hidden ${visible ? 'w-screen' : 'w-0'} z-50`}
                style={{ backgroundColor: theme.primary }}
            >
                <div className="flex flex-col p-5" style={{ color: theme.text }}>
                    <div className='flex items-center justify-between'>
                        <p>Menu</p>
                        <RiArrowDropDownLine className='rotate-90 text-3xl cursor-pointer' onClick={() => setVisible(false)} />
                    </div>
                    <NavLink onClick={() => setVisible(false)} to='/' className='py-2 border-b'>HOME</NavLink>
                    <NavLink onClick={() => setVisible(false)} to='/collection' className='py-2 border-b'>COLLECTION</NavLink>
                    {/* <NavLink onClick={() => setVisible(false)} to='/bundles' className='py-2 border-b'>BUNDLES</NavLink> */}
                    <NavLink onClick={() => setVisible(false)} to='/about' className='py-2 border-b'>ABOUT</NavLink>
                    <NavLink onClick={() => setVisible(false)} to='/contact' className='py-2 border-b'>CONTACT</NavLink>
                </div>
            </div>
        </div >
    );
};

export default Navbar;
