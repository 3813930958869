import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/home/Home';
import Products from './pages/shop/Products';
import ProductDetails from './pages/shop/ProductDetails';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Dashboard from './pages/customer/Dashboard';
import AdminPage from './pages/admin/Admin';
import PrivateRoute from './routes/PrivateRoute';
import ProtectedRoute from './routes/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';
import { ShopProvider } from './context/ShopContext';
import Navbar from './components/global/Navbar';
import Cart from './pages/customer/Cart';
import Footer from './components/footer/Footer';
import PlaceOrder from './pages/orders/PlaceOrder';
import Orders from './pages/orders/Orders';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import { ToastContainer } from 'react-toastify';
import Settings from './pages/settings/Settings';
import { ThemeProvider, useTheme } from './context/ThemeContext';
import Bundles from './pages/bundles/Bundles';
import Create from './pages/bundles/CustomBundles';
import NotFound from './pages/NotFound';

// Function to load Google Maps API script
const loadGoogleMapsScript = (apiKey) => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    document.body.appendChild(script);
};

// Layout component that applies the active theme class
function Layout({ children }) {
    const location = useLocation();
    const { theme } = useTheme(); // Use theme from ThemeContext

    // Check if the current path is within the admin section (including subroutes)
    const isAdminRoute = location.pathname.startsWith('/admin');

    return (
        <div
            className="min-h-screen transition-all"
            style={{ backgroundColor: theme.primary, color: theme.text }} // Apply theme styles inline
        >
            {/* Conditionally render Navbar and Footer only for non-admin routes */}
            {!isAdminRoute && (
                <div className="px-4 sm:px-[5vw] md:px-[7vw] lg:px-[9vw]">
                    <Navbar />
                </div>
            )}
            <div className={!isAdminRoute ? 'px-4 sm:px-[5vw] md:px-[7vw] lg:px-[9vw]' : ''}>
                {children}
            </div>
            {!isAdminRoute && (
                <div className="px-4 sm:px-[5vw] md:px-[7vw] lg:px-[9vw]">
                    <Footer />
                </div>
            )}
        </div>
    );
}

function App() {
    useEffect(() => {
        const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
        if (googleMapsApiKey) {
            loadGoogleMapsScript(googleMapsApiKey);
        }
    }, []);

    return (
        <Router>
            <AuthProvider>
                <ShopProvider>
                    <ThemeProvider>
                        <Layout>
                            <Routes>
                                {/* Public Routes */}
                                <Route path="/" element={<Home />} />
                                <Route path="/collection" element={<Products />} />
                                {/* <Route path="/bundles" element={<Bundles />} />
                                <Route path="/bundles/create" element={<Create />} /> */}
                                <Route path="/products/:productId" element={<ProductDetails />} />
                                <Route path="/about" element={<About />} />
                                <Route path="/contact" element={<Contact />} />
                                <Route path="/settings" element={<Settings />} />
                                <Route path="*" element={<NotFound />} />

                                {/* Protected Routes */}
                                <Route path="/auth/login" element={<ProtectedRoute><Login /></ProtectedRoute>} />
                                <Route path="/auth/register" element={<ProtectedRoute><Register /></ProtectedRoute>} />

                                {/* Private Routes */}
                                {/* <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} /> */}
                                <Route path="/cart" element={<PrivateRoute><Cart /></PrivateRoute>} />
                                <Route path="/place-order" element={<PrivateRoute><PlaceOrder /></PrivateRoute>} />
                                <Route path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>} />

                                {/* Admin Routes (exempt from Navbar and Footer) */}
                                <Route path="/admin/*" element={<PrivateRoute adminRequired={true}><AdminPage /></PrivateRoute>} />
                            </Routes>
                            <ToastContainer />
                        </Layout>
                    </ThemeProvider>
                </ShopProvider>
            </AuthProvider>
        </Router>
    );
}

export default App;
