// src/components/ImageRow.js
import React, { useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Assuming Firebase is properly set up
import { FaPlus } from 'react-icons/fa'; // React Icons for the add image icon
import Modal from 'react-modal'; // Modal for larger view
import imageCompression from 'browser-image-compression'; // Utility for converting images to WebP format

const ImageRow = ({ localImages, setLocalImages, handleImageDeleteFromFirebase }) => {
    const [activeImage, setActiveImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);

    // Handle file uploads and replace images in available slots
    const handleFileUpload = async (e, index) => {
        const file = e.target.files[0];
        if (!file) return;

        // Convert file to WebP format
        const webpFile = await convertToWebP(file);
        const preview = URL.createObjectURL(webpFile);

        // Update the specific image slot
        const updatedImages = [...localImages];
        updatedImages[index] = { file: webpFile, preview };
        setLocalImages(updatedImages);
        setActiveImage(preview);
    };

    const convertToWebP = async (file) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
            fileType: 'image/webp',
        };
        try {
            const compressedFile = await imageCompression(file, options);
            return compressedFile;
        } catch (error) {
            console.error('Error converting image to WebP:', error);
            return file;
        }
    };

    // Handle the deletion of an image from local state and Firebase
    const handleDeleteImageClick = (imageIndex) => {
        const imageToDelete = localImages[imageIndex];
        const updatedImages = localImages.map((img, index) => index === imageIndex ? null : img);
        setLocalImages(updatedImages);

        // Adjust the active image if the deleted image was active
        if (activeImage === imageToDelete?.preview) {
            setActiveImage(updatedImages.find(img => img)?.preview || '');
        }

        // Handle deleting the image from Firebase if it's already stored there
        if (imageToDelete?.firebaseUrl) {
            handleImageDeleteFromFirebase(imageToDelete.firebaseUrl);
        }
    };

    // Handle modal open/close
    const openModal = (image, index) => {
        if (image && image.preview) {
            setActiveImage(image.preview);
            setSelectedIndex(index);
            setIsModalOpen(true);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setActiveImage(null);
    };

    // Handle swapping images between slots
    const handleSwapImages = (fromIndex, toIndex) => {
        const updatedImages = [...localImages];
        const temp = updatedImages[fromIndex];
        updatedImages[fromIndex] = updatedImages[toIndex] || null;
        updatedImages[toIndex] = temp;
        setLocalImages(updatedImages.map(img => img || null)); // Ensure no undefined values are left in the array
    };

    // Handle drag start event
    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('imageIndex', index);
    };

    // Handle drop event to swap images
    const handleDrop = (e, index) => {
        e.preventDefault();
        const fromIndex = e.dataTransfer.getData('imageIndex');
        if (fromIndex !== null && fromIndex !== undefined && fromIndex !== '' && fromIndex !== index.toString()) {
            handleSwapImages(parseInt(fromIndex, 10), index);
        }
    };

    // Prevent default drag over behavior
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    return (
        <div className="w-full">
            <div className="flex flex-wrap gap-2">
                {Array.from({ length: 7 }).map((_, index) => (
                    <div
                        key={index}
                        className="relative group h-24 w-24 border-2 border-dashed border-gray-300 flex items-center justify-center"
                        draggable={!!localImages[index]}
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDrop={(e) => handleDrop(e, index)}
                        onDragOver={handleDragOver}
                    >
                        {localImages[index] ? (
                            <div className="relative h-full w-full">
                                <img
                                    src={localImages[index]?.preview || ''}
                                    alt="product"
                                    onClick={() => openModal(localImages[index], index)}
                                    className="h-full w-full cursor-pointer object-cover"
                                />
                            </div>
                        ) : (
                            <div className="flex items-center justify-center h-full w-full cursor-pointer">
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="opacity-0 absolute inset-0 cursor-pointer"
                                    onChange={(e) => handleFileUpload(e, index)}
                                />
                                <FaPlus className="text-gray-400 text-2xl" />
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* Modal for larger image view */}
            {isModalOpen && activeImage && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={closeModal}>
                    <div className="bg-white p-4 rounded-lg shadow-lg w-96 relative" onClick={(e) => e.stopPropagation()}>
                        <button
                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                            onClick={closeModal}
                        >
                            ✕
                        </button>
                        <div className="flex flex-col items-center">
                            <img
                                src={activeImage}
                                alt="product"
                                className="h-64 w-64 object-contain mb-4"
                            />
                            <button
                                onClick={() => {
                                    handleDeleteImageClick(selectedIndex);
                                    closeModal();
                                }}
                                className="btn btn-danger"
                            >
                                Delete Image
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

// Upload all images to Firebase and return URLs, maintaining order
const uploadImagesToFirebase = async (productId, localImages, storage) => {
    const uploadPromises = localImages.map(async (image, index) => {
        if (!image) return null; // Skip empty slots
        const storageRef = ref(storage, `products/${productId}/images/image_${index}`);
        await uploadBytes(storageRef, image.file);
        const url = await getDownloadURL(storageRef);
        return { firebaseUrl: url, preview: image.preview }; // Return both the Firebase URL and local preview
    });

    const uploadedImageUrls = await Promise.all(uploadPromises);
    return uploadedImageUrls.filter(Boolean); // Filter out any null values
};

export { ImageRow, uploadImagesToFirebase };
