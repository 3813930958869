import React, { useContext, useState, useEffect } from 'react';
import { ShopContext } from '../../context/ShopContext';
import Title from '../../components/global/Title';
import ProductItem from '../../components/collections/ProductItem';

const RelatedProducts = ({ category, subCategory, productId }) => {
    const { products } = useContext(ShopContext);
    const [related, setRelated] = useState([]);

    useEffect(() => {
        if (products.length > 0) {
            let productsCopy = products.slice();

            // Filter products by category, subCategory, and exclude sold/archived or the current product by id
            const filteredProducts = productsCopy.filter(
                (item) =>
                    category === item.category &&
                    subCategory === item.subCategory &&
                    item.id !== productId &&
                    !item.sold &&  // Exclude sold products
                    !item.archived  // Exclude archived products
            );

            // Limit the result to 5 items
            const limitedProducts = filteredProducts.slice(0, 5);

            // Update the related products state
            setRelated(limitedProducts);
        }
    }, [products, category, subCategory, productId]);

    return (
        <div className='my-24'>
            <div className='text-center text-3xl py-2'>
                <Title text1={'RELATED'} text2={'PRODUCTS'} />
            </div>
            <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 gap-y-6'>
                {
                    related.map((item, index) => (
                        <ProductItem
                            key={index}
                            id={item.id}
                            name={item.name}
                            price={item.price}
                            image={item.images[0]}
                            sold={item.sold}  // Pass sold status to ProductItem
                            archived={item.archived}  // Pass archived status to ProductItem
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default RelatedProducts;
