import { createContext, useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import { ref, getDownloadURL, listAll } from 'firebase/storage';
import { storage } from '../firebase';

export const ShopContext = createContext();

export const ShopProvider = (props) => {
    const currency = '$';
    const deliveryFee = 10; // Delivery fee can be used across the application
    const [search, setSearch] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const { currentUser } = useAuth(); // Authentication context to track current user

    // Fetch products and images from Firestore and Firebase Storage
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productsCollection = await getDocs(collection(db, 'products'));
                let productsData = await Promise.all(
                    productsCollection.docs.map(async (doc) => {
                        const data = doc.data();

                        // Fetch product images from Firebase Storage
                        const storageRef = ref(storage, `products/${doc.id}/images/`);
                        const res = await listAll(storageRef);
                        const imageUrls = await Promise.all(res.items.map((item) => getDownloadURL(item)));
                        return {
                            id: doc.id,
                            ...data,
                            images: imageUrls,
                            totalLikes: data.likes?.length || 0,
                            totalCart: data.cart?.length || 0,
                            totalWishlists: data.wishlists?.length || 0,
                            totalReviews: data.reviews?.length || 0,
                        };
                    })
                );
                setProducts(productsData);
                setFilteredProducts(productsData); // Initialize filtered products
            } catch (error) {
                console.error("Error fetching products: ", error);
            }
        };

        fetchProducts();
    }, []);

    // Fetch user's cart items from Firestore when the user is logged in
    useEffect(() => {
        const fetchCartItems = async () => {
            if (currentUser) {
                try {
                    const userDocRef = doc(db, 'customers', currentUser.uid);
                    const userDoc = await getDoc(userDocRef);

                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        setCartItems(userData.cart || []);
                    }
                } catch (error) {
                    console.error("Error fetching user cart: ", error);
                }
            }
        };

        fetchCartItems();
    }, [currentUser]);

    // Toggle like functionality for a product
    const toggleLike = async (productId, isLiked) => {
        const productRef = doc(db, 'products', productId);
        const userRef = doc(db, 'customers', currentUser.uid);

        if (isLiked) {
            await updateDoc(productRef, {
                likes: arrayRemove(currentUser.uid)
            });
            await updateDoc(userRef, {
                likedItems: arrayRemove(productId)
            });
        } else {
            await updateDoc(productRef, {
                likes: arrayUnion(currentUser.uid)
            });
            await updateDoc(userRef, {
                likedItems: arrayUnion(productId)
            });
        }
    };

    // Toggle wishlist functionality for a product
    const toggleWishlist = async (productId, isWishlisted) => {
        const productRef = doc(db, 'products', productId);
        const userRef = doc(db, 'customers', currentUser.uid);

        if (isWishlisted) {
            await updateDoc(productRef, {
                wishlists: arrayRemove(currentUser.uid)
            });
            await updateDoc(userRef, {
                wishlist: arrayRemove(productId)
            });
        } else {
            await updateDoc(productRef, {
                wishlists: arrayUnion(currentUser.uid)
            });
            await updateDoc(userRef, {
                wishlist: arrayUnion(productId)
            });
        }
    };

    // Add product to cart functionality
    const addToCart = async (product) => {
        const productRef = doc(db, 'products', product.id);
        const userRef = doc(db, 'customers', currentUser.uid);

        const isInCart = product.cart?.includes(currentUser.uid);

        if (!isInCart) {
            await updateDoc(productRef, {
                cart: arrayUnion(currentUser.uid)
            });

            await updateDoc(userRef, {
                cart: arrayUnion({
                    itemId: product.id,
                    name: product.name,
                    price: product.price,
                    quantity: 1,
                    image: product.images[0]
                })
            });

            setCartItems(prev => [...prev, product]);
        }
    };

    // Remove item from cart functionality
    const removeFromCart = async (productId) => {
        const userRef = doc(db, 'customers', currentUser.uid);
        const updatedCart = cartItems.filter(item => item.itemId !== productId);

        await updateDoc(userRef, {
            cart: updatedCart
        });

        setCartItems(updatedCart);
    };

    // Object to map color names to CSS class strings
    const colorClassMap = {
        // Basic Colors
        red: 'bg-red-500',
        blue: 'bg-blue-500',
        green: 'bg-green-500',
        yellow: 'bg-yellow-500',
        pink: 'bg-pink-500',
        purple: 'bg-purple-500',
        black: 'bg-black',
        white: 'bg-white',
        gray: 'bg-gray-500',
        grey: 'bg-gray-500',
        orange: 'bg-orange-500',
        brown: 'bg-[#8B4513]', // SaddleBrown
        cyan: 'bg-cyan-500',
        lime: 'bg-lime-500',
        khaki: 'bg-[#BDB76B]',

        // Pastel Colors
        pastelPink: 'bg-[#FFD1DC]',
        pastelBlue: 'bg-[#AEC6CF]',
        pastelGreen: 'bg-[#77DD77]',
        pastelYellow: 'bg-[#FDFD96]',
        pastelPurple: 'bg-[#CBAACB]',
        pastelOrange: 'bg-[#FFB347]',

        // Neutral Tones
        beige: 'bg-[#F5F5DC]',
        tan: 'bg-[#D2B48C]',
        ivory: 'bg-[#FFFFF0]',
        cream: 'bg-[#FFFDD0]',
        taupe: 'bg-[#483C32]',
        charcoal: 'bg-[#36454F]',

        // Earth Tones
        olive: 'bg-[#808000]',
        forestGreen: 'bg-[#228B22]',
        terracotta: 'bg-[#E2725B]',
        sand: 'bg-[#C2B280]',
        mustard: 'bg-[#FFDB58]',
        sienna: 'bg-[#A0522D]',

        // Bright and Vibrant Colors
        electricBlue: 'bg-[#7DF9FF]',
        neonGreen: 'bg-[#39FF14]',
        hotPink: 'bg-[#FF69B4]',
        neonOrange: 'bg-[#FF4500]',
        brightYellow: 'bg-[#FFD700]',
        magenta: 'bg-[#FF00FF]',
        teal: 'bg-[#008080]',

        // Jewel Tones
        emerald: 'bg-[#50C878]',
        sapphire: 'bg-[#0F52BA]',
        ruby: 'bg-[#E0115F]',
        amethyst: 'bg-[#9966CC]',
        garnet: 'bg-[#733635]',
        amber: 'bg-[#FFBF00]',

        // Metallics
        gold: 'bg-[#FFD700]',
        silver: 'bg-[#C0C0C0]',
        bronze: 'bg-[#CD7F32]',
        copper: 'bg-[#B87333]',
        roseGold: 'bg-[#B76E79]',

        // Denim Variants
        lightDenim: 'bg-[#B0C4DE]',
        darkDenim: 'bg-[#264653]',
        washedDenim: 'bg-[#A9A9A9]',

        // Gradient and Special Patterns
        multi: 'bg-gradient-to-r from-red-400 via-blue-400 to-purple-400',
        ombrePink: 'bg-gradient-to-b from-pink-300 to-pink-600',
        ombreBlue: 'bg-gradient-to-b from-blue-300 to-blue-600',
        tieDye: 'bg-gradient-to-r from-yellow-400 via-pink-500 to-blue-500',

        navy: 'bg-[#000080]',
    };

    const value = {
        currency,
        deliveryFee, // Delivery fee is available in the context
        search,
        setSearch,
        showSearch,
        setShowSearch,
        products,
        filteredProducts,
        setFilteredProducts,
        cartItems,
        setCartItems,
        toggleLike,
        toggleWishlist,
        addToCart,
        removeFromCart,
        colorClassMap // Map of colors for product display
    };

    return (
        <ShopContext.Provider value={value}>
            {props.children}
        </ShopContext.Provider>
    );
};
