import React from 'react';
import { Link } from 'react-router-dom';
import { ShopContext } from '../../context/ShopContext';
import { useTheme } from '../../context/ThemeContext'; // Import theme context

const ProductItem = ({ id, image, name, price, sold, archived }) => {
    const { currency } = React.useContext(ShopContext);
    const { theme } = useTheme(); // Use the theme from ThemeContext

    // Do not display the product if it's sold or archived
    if (sold || archived) {
        return null; // Do not render anything
    }

    return (
        <Link to={`/products/${id}`} className='cursor-pointer' style={{ color: theme.text }}>
            <div className='overflow-hidden sm:h-[350px] w-full'>
                <img
                    src={image}
                    className='hover:scale-110 transition ease-in-out h-full w-full object-cover'
                    alt={name}
                />
            </div>
            <p className='pt-3 pb-1 text-sm' style={{ color: theme.text }}>{name}</p>
            <p className='text-sm font-medium' style={{ color: theme.text }}>
                {currency}{price}
            </p>
        </Link>
    );
};

export default ProductItem;
