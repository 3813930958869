import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { FaUser } from "react-icons/fa";
import { AiFillProduct } from "react-icons/ai";
import { MdLocalShipping } from "react-icons/md";
import { useTheme } from '../../context/ThemeContext';
import { CSSTransition } from 'react-transition-group';
import './CurrentOrder.css'; // Import the CSS file for transitions

const CurrentOrder = ({ order, setActiveOrder }) => {
    const db = getFirestore();
    const [orderDetails, setOrderDetails] = useState(null);
    const [customerInfo, setCustomerInfo] = useState({});
    const [productInfo, setProductInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [isShipping, setIsShipping] = useState(false);
    const [showShippingInfo, setShowShippingInfo] = useState(false);
    const [showCustomerInfo, setShowCustomerInfo] = useState(false);
    const [showProductInfo, setShowProductInfo] = useState(false);
    const orderTotal = `$${(parseFloat(orderDetails?.total || productInfo.price || 0) + parseFloat(orderDetails?.shippingFee || 0)).toFixed(2)}`;
    const { theme } = useTheme();

    useEffect(() => {
        const fetchOrderDetails = async () => {
            setLoading(true);
            try {
                if (!order?.id) {
                    setLoading(false);
                    return;
                }

                const orderRef = doc(db, 'orders', order.id);
                const orderSnap = await getDoc(orderRef);

                if (orderSnap.exists()) {
                    const orderData = orderSnap.data();
                    setOrderDetails(orderData);

                    const fetchedCustomerInfo = await fetchCustomerInfo(orderData.customerId);
                    const fetchedProductInfo = await fetchProductInfo(orderData.productId);

                    setCustomerInfo(fetchedCustomerInfo);
                    setProductInfo(fetchedProductInfo);
                }
            } catch (error) {
                console.error('Error fetching order details:', error);
            } finally {
                setLoading(false);
            }
        };

        if (order) {
            fetchOrderDetails();
        }
    }, [order]);

    const fetchCustomerInfo = async (customerId) => {
        if (!customerId) return { displayName: 'Unknown', email: 'Unknown' };
        try {
            const customerRef = doc(db, 'customers', customerId);
            const customerSnap = await getDoc(customerRef);
            return customerSnap.exists() ? customerSnap.data() : { displayName: 'Unknown', email: 'Unknown' };
        } catch {
            return { displayName: 'Unknown', email: 'Unknown' };
        }
    };

    const fetchProductInfo = async (productId) => {
        if (!productId) return { name: 'Product not found', imageUrl: '/placeholder.png', price: 'N/A' };
        try {
            const productRef = doc(db, 'products', productId);
            const productSnap = await getDoc(productRef);
            const productData = productSnap.data();
            return productSnap.exists() ? { ...productData, imageUrl: productData.images?.[0]?.url || '/placeholder.png' } : { name: 'Product not found', imageUrl: '/placeholder.png', price: 'N/A' };
        } catch {
            return { name: 'Error fetching product', imageUrl: '/placeholder.png', price: 'N/A' };
        }
    };

    const handleShipOrder = async () => {
        if (!order?.id) return;
        setIsShipping(true);
        try {
            const orderRef = doc(db, 'orders', order.id);
            await updateDoc(orderRef, { shipped: true });
            setOrderDetails((prevDetails) => ({ ...prevDetails, shipped: true }));
            alert('Order marked as shipped!');
        } catch (error) {
            console.error('Error updating order status:', error);
        } finally {
            setIsShipping(false);
        }
    };

    if (loading) {
        return <div className="text-center"><span className="loading loading-spinner loading-lg"></span></div>;
    }

    if (!orderDetails) {
        return <div className="text-center">No order details found.</div>;
    }

    const copyToClipBoard = (text) => {
        navigator.clipboard.writeText(text);
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    };

    const productPrice = parseFloat(orderDetails.total || productInfo.price || 0);
    const shippingFee = parseFloat(orderDetails.shippingFee || 0);
    const totalPrice = (productPrice + shippingFee).toFixed(2);

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="relative bg-white w-11/12 sm:w-2/3 md:w-1/2 lg:w-1/3 p-4 rounded shadow-lg z-10">
                {/* Close button to dismiss the popup */}
                <button
                    className="absolute top-2 right-2 p-2 rounded-full"
                    onClick={() => setActiveOrder(null)}
                    style={{ color: theme.text }}
                >
                    X
                </button>
                <div className='flex flex-row justify-center space-x-5'>
                    <button
                        className='p-4 rounded-2xl'
                        onClick={() => {
                            setShowCustomerInfo((prev) => !prev);
                            setShowShippingInfo(false);
                            setShowProductInfo(false);
                        }}
                        style={{
                            backgroundColor: showCustomerInfo ? theme.accent : theme.secondary,
                            color: showCustomerInfo ? theme.alttext : theme.text
                        }}
                    >
                        <FaUser size={96} />
                    </button>
                    <button
                        className='p-4 rounded-2xl'
                        onClick={() => {
                            setShowShippingInfo((prev) => !prev);
                            setShowCustomerInfo(false);
                            setShowProductInfo(false);
                        }}
                        style={{
                            backgroundColor: showShippingInfo ? theme.accent : theme.secondary,
                            color: showShippingInfo ? theme.alttext : theme.text
                        }}
                    >
                        <MdLocalShipping size={96} />
                    </button>
                    <button
                        className='p-4 rounded-2xl'
                        onClick={() => {
                            setShowProductInfo((prev) => !prev);
                            setShowCustomerInfo(false);
                            setShowShippingInfo(false);
                        }}
                        style={{
                            backgroundColor: showProductInfo ? theme.accent : theme.secondary,
                            color: showProductInfo ? theme.alttext : theme.text
                        }}
                    >
                        <AiFillProduct size={96} />
                    </button>
                </div>

                <div className="tab-content-container">
                    <CSSTransition in={showCustomerInfo} timeout={300} className="fade" unmountOnExit>
                        <div className="tab-content" style={{ color: theme.text }}>
                            <div className='flex flex-row space-x-2 mt-5 justify-center border-t pt-5' style={{ borderColor: theme.accent }}>
                                <div className='flex flex-col'>
                                    <span className="label-text">Customer Name</span>
                                    <input type="text" readOnly value={customerInfo.displayName || 'N/A'} className="input input-bordered w-full max-w-xs cursor-pointer" onClick={() => copyToClipBoard(customerInfo.displayName || 'N/A')} />
                                </div>
                                <div className='flex flex-col'>
                                    <span className="label-text">Customer Email</span>
                                    <input type="text" readOnly value={customerInfo.email || 'N/A'} className="input input-bordered w-full max-w-xs cursor-pointer" onClick={() => copyToClipBoard(customerInfo.email || 'N/A')} />
                                </div>
                                <div className='flex flex-col'>
                                    <span className='label-text'>Customer Phone Number</span>
                                    <input type='text' readOnly value={orderDetails.shippingInfo.phone || 'N/A'} className='input input-bordered w-full max-w-xs cursor-pointer' onClick={() => copyToClipBoard(orderDetails.shippingInfo.phone || 'N/A')} />
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                    <CSSTransition in={showShippingInfo} timeout={300} className="fade" unmountOnExit>
                        <div className="tab-content" style={{ color: theme.text }}>
                            <div className='flex flex-row space-x-2 mt-5 justify-center border-t pt-5' style={{ borderColor: theme.accent }}>
                                <div className='flex flex-col'>
                                    <span className="label-text">Shipping Address</span>
                                    <input type="text" readOnly value={orderDetails.shippingInfo.street || 'N/A'} className="input input-bordered w-full max-w-xs cursor-pointer" onClick={() => copyToClipBoard(customerInfo.displayName || 'N/A')} />
                                </div>
                                <div className='flex flex-col'>
                                    <span className="label-text">City</span>
                                    <input type="text" readOnly value={orderDetails.shippingInfo.city || 'N/A'} className="input input-bordered w-full max-w-xs cursor-pointer" onClick={() => copyToClipBoard(customerInfo.email || 'N/A')} />
                                </div>
                                <div className='flex flex-col'>
                                    <span className='label-text'>State</span>
                                    <input type='text' readOnly value={orderDetails.shippingInfo.state || 'N/A'} className='input input-bordered w-full max-w-xs cursor-pointer' onClick={() => copyToClipBoard(orderDetails.shippingInfo.phone || 'N/A')} />
                                </div>
                            </div>
                            <div className='flex flex-row space-x-2 mt-5 justify-center'>
                                <div className='flex flex-col'>
                                    <span className="label-text">Country</span>
                                    <input type="text" readOnly value={orderDetails.shippingInfo.country || 'N/A'} className="input input-bordered w-full max-w-xs cursor-pointer" onClick={() => copyToClipBoard(customerInfo.displayName || 'N/A')} />
                                </div>
                                <div className='flex flex-col'>
                                    <span className="label-text">Zipcode</span>
                                    <input type="text" readOnly value={orderDetails.shippingInfo.zipcode || 'N/A'} className="input input-bordered w-full max-w-xs cursor-pointer" onClick={() => copyToClipBoard(customerInfo.email || 'N/A')} />
                                </div>
                                <div className='flex flex-col'>
                                    <span className='label-text'>Shipping Status</span>
                                    <input type='text' readOnly value={orderDetails.shipped ? 'Shipped' : 'Awaiting Shipping'} className='input input-bordered w-full max-w-xs cursor-pointer' onClick={() => copyToClipBoard(orderDetails.shippingInfo.phone || 'N/A')} />
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                    <CSSTransition in={showProductInfo} timeout={300} className="fade" unmountOnExit>
                        <div className="tab-content" style={{ color: theme.text }}>
                            <div className='flex flex-row space-x-2 mt-5 justify-center border-t pt-5' style={{ borderColor: theme.accent }}>
                                <div className='flex flex-col'>
                                    <span className="label-text">Order Total</span>
                                    <input type="text" readOnly value={orderTotal || 'N/A'} className="input input-bordered w-full max-w-xs cursor-pointer" onClick={() => copyToClipBoard(customerInfo.displayName || 'N/A')} />
                                </div>
                                <div className='flex flex-col'>
                                    <span className="label-text">Item Unit Price</span>
                                    <input type="text" readOnly value={orderDetails.unitPrice || 'N/A'} className="input input-bordered w-full max-w-xs cursor-pointer" onClick={() => copyToClipBoard(customerInfo.email || 'N/A')} />
                                </div>
                                <div className='flex flex-col'>
                                    <span className='label-text'>Delivery Fee</span>
                                    <input type='text' readOnly value={orderDetails.deliveryFee || 'N/A'} className='input input-bordered w-full max-w-xs cursor-pointer' onClick={() => copyToClipBoard(orderDetails.shippingInfo.phone || 'N/A')} />
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                    <div className='flex flex-row space-x-2 mt-5 justify-center border-t pt-5' style={{ borderColor: theme.accent }}>
                        <div className='flex flex-col'>
                            <span className="label-text">Order Status</span>
                            <input type="text" readOnly value={orderDetails.active ? 'Active' : 'Completed' || 'N/A'} className="input input-bordered w-full max-w-xs cursor-pointer" onClick={() => copyToClipBoard(customerInfo.displayName || 'N/A')} />
                        </div>
                        <div className='flex flex-col'>
                            <span className="label-text">Item Unit Price</span>
                            <input type="text" readOnly value={orderDetails.unitPrice || 'N/A'} className="input input-bordered w-full max-w-xs cursor-pointer" onClick={() => copyToClipBoard(customerInfo.email || 'N/A')} />
                        </div>
                        <div className='flex flex-col'>
                            <span className='label-text'>Time of Purchase</span>
                            <input type='text' readOnly value={formatDate(orderDetails.timeOfPurchase) || 'N/A'} className='input input-bordered w-full max-w-xs cursor-pointer' onClick={() => copyToClipBoard(orderDetails.shippingInfo.phone || 'N/A')} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CurrentOrder;
