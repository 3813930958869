import React, { useContext, useState, useEffect } from 'react';
import { ShopContext } from '../../context/ShopContext';
import { RiArrowDropDownLine } from "react-icons/ri";
import ProductItem from '../../components/collections/ProductItem';
import Title from '../../components/global/Title';
import { useTheme } from '../../context/ThemeContext'; // Import theme context

const Products = () => {
    const { products, filteredProducts, setFilteredProducts, search, showSearch } = useContext(ShopContext);
    const { theme } = useTheme(); // Use theme from ThemeContext
    const [showFilter, setShowFilter] = useState(false);
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [sortType, setSortType] = useState('relevant');

    const toggleFilter = (e, setFilter) => {
        const value = e.target.value;
        setFilter(prev =>
            prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
        );
    };

    const applyFilter = () => {
        let productsCopy = products.slice();

        if (showSearch && search) {
            const lowerCaseSearch = search.toLowerCase();
            productsCopy = productsCopy.filter(item =>
                item.name.toLowerCase().includes(lowerCaseSearch) ||
                (item.sizes && Object.keys(item.sizes).some(size => size.toLowerCase().includes(lowerCaseSearch) && item.sizes[size] > 0))
            );
        }

        if (category.length > 0) {
            productsCopy = productsCopy.filter(item => category.includes(item.category));
        }

        if (subCategory.length > 0) {
            productsCopy = productsCopy.filter(item => subCategory.includes(item.subCategory));
        }

        if (sizes.length > 0) {
            productsCopy = productsCopy.filter(item => Object.keys(item.sizes)?.some(size => sizes.includes(size) && item.sizes[size] > 0));
        }

        // Filter out products that are sold or archived
        productsCopy = productsCopy.filter(item => !item.sold && !item.archived);

        setFilteredProducts(productsCopy);
    };

    const sortProduct = () => {
        let fpCopy = filteredProducts.slice();
        switch (sortType) {
            case 'low-high':
                setFilteredProducts(fpCopy.sort((a, b) => a.price - b.price));
                break;
            case 'high-low':
                setFilteredProducts(fpCopy.sort((a, b) => b.price - a.price));
                break;
            default:
                applyFilter();
                break;
        }
    };

    useEffect(() => {
        applyFilter();
    }, [category, subCategory, sizes, search, showSearch]);

    useEffect(() => {
        sortProduct();
    }, [sortType]);

    return (
        <div className='flex flex-col sm:flex-row gap-1 sm:gap-10 pt-10 border-t' style={{ color: theme.text, borderColor: theme.border }}>
            {/* Filter Section */}
            <div className='min-w-60'>
                <p onClick={() => setShowFilter(!showFilter)} className='my-2 text-xl flex items-center cursor-pointer gap-2'>
                    FILTERS
                    <RiArrowDropDownLine className={`sm:hidden ${showFilter ? 'rotate-0' : '-rotate-90'}`} size={32} />
                </p>
                {/* Category Filter */}
                <div className={`border border-gray-300 pl-5 py-3 mt-6 ${showFilter ? '' : 'hidden'} sm:block`}>
                    <p className='mb-3 text-sm font-medium'>CATEGORIES</p>
                    <div className='flex flex-col gap-2 text-sm font-light' style={{ color: theme.text }}>
                        <p className='flex gap-2 items-center'>
                            <input type='checkbox' className='checkbox checkbox-primary' value={'men'} onChange={(e) => toggleFilter(e, setCategory)} />
                            <span>Men</span>
                        </p>
                        <p className='flex gap-2 items-center'>
                            <input type='checkbox' className='checkbox checkbox-primary' value={'women'} onChange={(e) => toggleFilter(e, setCategory)} />
                            <span>Women</span>
                        </p>
                        <p className='flex gap-2 items-center'>
                            <input type='checkbox' className='checkbox checkbox-primary' value={'kids'} onChange={(e) => toggleFilter(e, setCategory)} />
                            <span>Kids</span>
                        </p>
                    </div>
                </div>
                {/* SubCategory Filter */}
                <div className={`border border-gray-300 pl-5 py-3 my-5 ${showFilter ? '' : 'hidden'} sm:block`}>
                    <p className='mb-3 text-sm font-medium'>TYPE</p>
                    <div className='flex flex-col gap-2 text-sm font-light' style={{ color: theme.text }}>
                        <p className='flex gap-2 items-center'>
                            <input type='checkbox' className='checkbox checkbox-primary' value={'tops'} onChange={(e) => toggleFilter(e, setSubCategory)} />
                            <span>Tops</span>
                        </p>
                        <p className='flex gap-2 items-center'>
                            <input type='checkbox' className='checkbox checkbox-primary' value={'bottoms'} onChange={(e) => toggleFilter(e, setSubCategory)} />
                            <span>Bottoms</span>
                        </p>
                        <p className='flex gap-2 items-center'>
                            <input type='checkbox' className='checkbox checkbox-primary' value={'accessories'} onChange={(e) => toggleFilter(e, setSubCategory)} />
                            <span>Accessories</span>
                        </p>
                        <p className='flex gap-2 items-center'>
                            <input type='checkbox' className='checkbox checkbox-primary' value={'purses'} onChange={(e) => toggleFilter(e, setSubCategory)} />
                            <span>Purses</span>
                        </p>
                        <p className='flex gap-2 items-center'>
                            <input type='checkbox' className='checkbox checkbox-primary' value={'shoes'} onChange={(e) => toggleFilter(e, setSubCategory)} />
                            <span>Shoes</span>
                        </p>
                    </div>
                </div>
            </div>
            {/* Products Section */}
            <div className='flex-1'>
                <div className='flex justify-between mb-4'>
                    <Title text1={'ALL'} text2={'COLLECTIONS'} />
                    {/* Product Sort */}
                    <select onChange={(e) => setSortType(e.target.value)} className='select border border-gray-300 text-sm px-2 w-44 max-w-sm'>
                        <option value='relevant'>Sort by: Relevant</option>
                        <option value='low-high'>Sort by: Low to High</option>
                        <option value='high-low'>Sort by: High to Low</option>
                    </select>
                </div>

                {/* Map Products */}
                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 gap-y-6'>
                    {
                        filteredProducts.map((item, index) => (
                            <ProductItem
                                key={index}
                                name={item.name}
                                id={item.id}
                                price={item.price}
                                image={item.images[0]} // Display the first image
                                sold={item.sold}  // Pass sold status to ProductItem
                                archived={item.archive}  // Pass archived status to ProductItem
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default Products;
