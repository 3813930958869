import React from 'react'
import { useTheme } from '../context/ThemeContext'

const NotFound = () => {
    const { theme } = useTheme() // Get the active theme from ThemeContext
    return (
        <div className='flex justify-center items-center mt-32'>
            <h1 className='text-4xl font-semibold' style={{ color: theme.accent }}>404 - Page Not Found</h1>
        </div>
    )
}

export default NotFound