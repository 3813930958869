import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
    const { currentUser } = useAuth();

    if (currentUser) {
        // If the user is logged in, redirect them to the profile page
        return <Navigate to="/dashboard" />;
    }

    // If not logged in, allow them to access the page
    return children;
};

export default ProtectedRoute;
