import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ShopContext } from '../../context/ShopContext';
import './ProductDetails.css';
import { useAuth } from '../../context/AuthContext';
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { FaShoppingBag } from "react-icons/fa";
import { PiThumbsUpBold, PiThumbsUpFill } from "react-icons/pi";
import { doc, updateDoc, arrayUnion, arrayRemove, onSnapshot } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { ref, getDownloadURL, listAll } from 'firebase/storage';
import RelatedProducts from './RelatedProducts';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoDotFill } from "react-icons/go";
import { MdOutlineLocalShipping } from "react-icons/md";
import { LuShieldCheck } from "react-icons/lu";
import { useTheme } from '../../context/ThemeContext'; // Import theme context

const ProductDetails = () => {
	const { productId } = useParams();
	const { colorClassMap } = useContext(ShopContext);
	const { currentUser } = useAuth();
	const { theme } = useTheme(); // Use theme from ThemeContext
	const [productData, setProductData] = useState(null);
	const [selectedImage, setSelectedImage] = useState('');
	const [images, setImages] = useState([]);
	const [isLiked, setIsLiked] = useState(false);
	const [isWishlisted, setIsWishlisted] = useState(false);
	const [totalLikes, setTotalLikes] = useState(0);
	const [totalWishlists, setTotalWishlists] = useState(0);
	const [totalInCart, setTotalInCart] = useState(0);
	const [totalReviews, setTotalReviews] = useState(0);
	const navigate = useNavigate();

	// Fetch product images from Firebase storage
	const fetchImages = async () => {
		const imagesRef = ref(storage, `products/${productId}/images`);
		try {
			const result = await listAll(imagesRef);
			const imagePromises = result.items.map(item => getDownloadURL(item));
			const urls = await Promise.all(imagePromises);
			setImages(urls);
			if (urls.length > 0) {
				setSelectedImage(urls[0]);
			}
		} catch (error) {
			console.error('Error fetching images from Firebase Storage:', error);
		}
	};

	useEffect(() => {
		if (!productId) return;

		const productRef = doc(db, 'products', productId);

		const unsubscribe = onSnapshot(productRef, (docSnapshot) => {
			if (docSnapshot.exists()) {
				const product = docSnapshot.data();
				setProductData(product);
				setTotalLikes(product.likes?.length || 0);
				setTotalWishlists(product.wishlist?.length || 0);
				setTotalInCart(product.cart?.length || 0);
				setTotalReviews(product.reviews?.length || 0);
				if (currentUser) {
					setIsLiked(product.likes?.includes(currentUser.uid));
					setIsWishlisted(product.wishlist?.includes(currentUser.uid));
				}
			}
		});

		fetchImages();

		return () => unsubscribe();
	}, [productId, currentUser]);

	const redirectToLogin = () => {
		navigate('/auth/login');
	};

	const handleLikeToggle = async () => {
		if (!currentUser) {
			redirectToLogin();
			return;
		}

		const productRef = doc(db, 'products', productId);
		const userRef = doc(db, 'customers', currentUser.uid);

		if (isLiked) {
			await updateDoc(productRef, {
				likes: arrayRemove(currentUser.uid)
			});
			await updateDoc(userRef, {
				likes: arrayRemove(productId)
			});
			setIsLiked(false);
		} else {
			await updateDoc(productRef, {
				likes: arrayUnion(currentUser.uid)
			});
			await updateDoc(userRef, {
				likes: arrayUnion(productId)
			});
			setIsLiked(true);
		}
	};

	const handleWishlistToggle = async () => {
		if (!currentUser) {
			redirectToLogin();
			return;
		}

		const productRef = doc(db, 'products', productId);
		const userRef = doc(db, 'customers', currentUser.uid);

		if (isWishlisted) {
			await updateDoc(productRef, {
				wishlist: arrayRemove(currentUser.uid)
			});
			await updateDoc(userRef, {
				wishlist: arrayRemove(productId)
			});
			setIsWishlisted(false);
		} else {
			await updateDoc(productRef, {
				wishlist: arrayUnion(currentUser.uid)
			});
			await updateDoc(userRef, {
				wishlist: arrayUnion(productId)
			});
			setIsWishlisted(true);
		}
	};

	const handleAddToCart = async () => {
		if (!currentUser) {
			redirectToLogin();
			return;
		}

		const productRef = doc(db, 'products', productId);
		const userRef = doc(db, 'customers', currentUser.uid);

		const isInCart = productData.cart?.includes(currentUser.uid);

		if (!isInCart) {
			await updateDoc(productRef, {
				cart: arrayUnion(currentUser.uid)
			});

			await updateDoc(userRef, {
				cart: arrayUnion({
					itemId: productId,
					name: productData.name,
					price: productData.price,
					quantity: 1,
				})
			});

			setTotalInCart(totalInCart + 1);
		}
	};

	const handlePurchase = async () => {
		if (!currentUser) {
			redirectToLogin();
			return;
		}

		// Add the product to the cart
		await handleAddToCart();

		// Navigate to the place order page
		navigate('/place-order');
	};

	if (!productData || images.length === 0) {
		return <div>Loading...</div>;
	}

	return (
		<div className='border-t-2 pt-10 transition-opacity ease-in duration-500 opacity-100' style={{ color: theme.text }}>
			<div className='flex gap-12 sm:gap-12 flex-col sm:flex-row'>
				<div className='flex-1 flex flex-col-reverse gap-3 sm:flex-row'>
					<div className='flex sm:flex-col custom-scrollbar sm:h-[600px] h-auto overflow-x-auto sm:overflow-y-auto justify-between sm:justify-start sm:w-[18.7%] w-full'>
						{
							images.map((image, index) => (
								<img
									key={index}
									src={image}
									alt={`Product ${index}`}
									onClick={() => setSelectedImage(image)}
									className={`w-[24%] sm:w-full sm:mb-3 flex-shrink-0 cursor-pointer ${selectedImage === image ? 'border-2 border-blue-500' : ''}`}
								/>
							))
						}
					</div>
					<div className='w-full sm:w-[80%] sm:h-[600px] h-auto flex justify-center items-center'>
						<img src={selectedImage} alt='Selected product' className='w-full h-full object-contain' />
					</div>
				</div>
				<div className='flex-1'>
					<h1 className='text-2xl font-medium mt-2' style={{ color: theme.text }}>{productData.name}</h1>
					<div className='flex items-center gap-6 mt-2'>
						<button className='flex items-center' onClick={handleLikeToggle}>
							{isLiked ? (
								<MdFavorite style={{ color: theme.accent }} size={32} />
							) : (
								<MdFavoriteBorder style={{ color: theme.text }} size={32} />
							)}
							<span className='ml-2' style={{ color: theme.text }}>{totalLikes}</span>
						</button>

						<button className='flex items-center' onClick={handleWishlistToggle}>
							{isWishlisted ? (
								<PiThumbsUpFill style={{ color: theme.highlight }} size={32} />
							) : (
								<PiThumbsUpBold style={{ color: theme.text }} size={32} />
							)}
							<span className='ml-2' style={{ color: theme.text }}>{totalWishlists}</span>
						</button>

						<div className='flex items-center'>
							<FaShoppingBag style={{ color: theme.alt }} size={32} />
							<span className='ml-2' style={{ color: theme.text }}>{totalInCart}</span>
						</div>
					</div>
					<p className='mt-3 text-3xl font-medium' style={{ color: theme.text }}>${productData.price}</p>
					<div className='flex flex-row items-center gap-2'>
						<p className='mt-3' style={{ color: theme.text }}>Size {productData.size.charAt(0).toUpperCase() + productData.size.slice(1)}</p>
						<GoDotFill className='translate-y-[7px]' size={14} />
						<p className='mt-3' style={{ color: theme.text }}>{productData.condition.charAt(0).toUpperCase() + productData.condition.slice(1)} condition</p>
					</div>
					<div className='flex flex-col py-2 sm:w-3/5'>
						<button
							className='btn btn-outline mt-3 px-8 py-3 text-sm w-full'
							style={{
								backgroundColor: theme.primary,
								color: theme.text,
								borderColor: theme.accent
							}}
							onMouseEnter={(e) => {
								e.target.style.backgroundColor = theme.accent;
								e.target.style.color = theme.primary;
							}}
							onMouseLeave={(e) => {
								e.target.style.backgroundColor = theme.primary;
								e.target.style.color = theme.text;
							}}
							onClick={handlePurchase}
						>
							BUY NOW
						</button>

						<button
							className='btn btn-outline mt-2 px-8 py-3 text-sm w-full'
							style={{
								backgroundColor: theme.accent,
								color: theme.primary,
								borderColor: theme.accent
							}}
							onMouseEnter={(e) => {
								e.target.style.backgroundColor = theme.primary;
								e.target.style.color = theme.text;
							}}
							onMouseLeave={(e) => {
								e.target.style.backgroundColor = theme.accent;
								e.target.style.color = theme.primary;
							}}
							onClick={handleAddToCart}
						>
							ADD TO CART
						</button>

						<div className='flex flex-col sm:flex-row mt-4 items-center'>
							<LuShieldCheck className='mt-2 mr-3' size={24} />
							<p className='text-m mt-2' style={{ color: theme.text }}>All purchases are handled through <span onClick={() => navigate('/help#stripe')} className='text-purple-400 cursor-pointer'>Stripe</span> & <span onClick={() => navigate('/help#paypal')} className='text-sky-500 cursor-pointer'>Paypal</span></p>
						</div>
						<div className='flex flex-col sm:flex-row mt-2 items-center text-center sm:text-left'>
							<MdOutlineLocalShipping className='mt-2 mr-3' size={24} />
							<p className='text-m mt-2' style={{ color: theme.text }}>Products are usually shipped within 24 hours of purchase</p>
						</div>
					</div>
					<hr className='mt-5 sm:w-3/5' />
					<p className='mt-3 md:w-3/5' style={{ color: theme.text }}>{productData.description}</p>
					<p className='mt-3 w-3/5'>
						{
							productData.tags.map((tag, index) => (
								<a href='/' key={index} className='mr-2' style={{ color: theme.accent }}>#{tag}</a>
							))
						}
					</p>
					<div className='flex flex-row mt-5 gap-2 w-3/5 items-center flex-wrap'>
						<p className='flex flex-wrap gap-2 items-center'>
							{productData.colors.map((color, index) => (
								<div key={index} className='flex items-center gap-1'>
									<div
										className={`${colorClassMap[color]} badge badge-lg cursor-pointer`}
										style={{ width: '24px', height: '24px', borderRadius: '50%' }}
									></div>
									<span style={{ color: theme.text }}>{color.charAt(0).toUpperCase() + color.slice(1)}</span>
								</div>
							))}
						</p>

						{/* Dot Separator */}
						<GoDotFill className='text-slate-400' size={14} />

						<p className='flex flex-wrap gap-2 items-center'>
							{productData.styles.map((style, index) => (
								<div key={index} className='flex items-center gap-1'>
									<span style={{ color: theme.text }}>{style.charAt(0).toUpperCase() + style.slice(1)}</span>
								</div>
							))}
						</p>
					</div>
					<p className='mt-4' style={{ color: theme.text }}>
						Listed At: {new Date(productData.listed_at).toLocaleDateString('en-US', {
							year: 'numeric',
							month: 'long',
							day: 'numeric',
						})}{' '}
						at {new Date(productData.listed_at).toLocaleTimeString('en-US', {
							hour: '2-digit',
							minute: '2-digit',
						})}
					</p>
					<hr className='mt-3 sm:w-3/5' />
				</div>
			</div>
			{/* <div className='mt-20'>
				<div className='flex'>
					<p className='border px-5 py-3 text-sm' style={{ color: theme.text }}>Reviews ({totalReviews})</p>
				</div>
				<div className='flex flex-col gap-4 border px-6 py-6 text-sm' style={{ color: theme.text }}>
					No Reviews Yet
				</div>
			</div> */}
			<RelatedProducts category={productData.category} subCategory={productData.subCategory} productId={productId} />

		</div>
	);
};

export default ProductDetails;
