import React from 'react';
import { useTheme } from '../../context/ThemeContext'; // Use theme

const Title = ({ text1, text2 }) => {
    const { theme } = useTheme();

    return (
        <div className='inline-flex gap-2 items-center mb-3'>
            <p className='w-8 sm:w-12 h-[1px] sm:h-[2px]' style={{ backgroundColor: theme.accent }}></p>
            <p style={{ color: theme.text }}>{text1} <span style={{ color: theme.accent, fontWeight: 'bold' }}>{text2}</span></p>
            <p className='w-8 sm:w-12 h-[1px] sm:h-[2px]' style={{ backgroundColor: theme.accent }}></p>
        </div>
    );
};

export default Title;
