import React, { useContext, useEffect, useState } from 'react';
import { ShopContext } from '../../context/ShopContext';
import Title from '../global/Title';
import ProductItem from '../collections/ProductItem';
import { useTheme } from '../../context/ThemeContext';

const LatestCollection = () => {
    const { products } = useContext(ShopContext);
    const { theme } = useTheme();
    const [latestProducts, setLatestProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state

    useEffect(() => {
        if (products && products.length > 0) {
            const sortedProducts = products
                .filter(product =>
                    product.listed_at && // Product must have a listed date
                    !product.sold &&     // Skip sold products
                    !product.archive     // Skip archived products (updated here)
                )
                .sort((a, b) => new Date(b.listed_at) - new Date(a.listed_at)); // Sort by listed date (newest first)

            setLatestProducts(sortedProducts.slice(0, 10));
        } else {
            setLatestProducts([]); // Clear state if no products
        }
        setIsLoading(false); // Stop loading after processing
    }, [products]);

    return (
        <div className="my-10">
            <div className="text-center py-8 text-3xl">
                <Title text1={'LATEST'} text2={'ARRIVALS'} />
                <p
                    className="w-3/3 m-auto text-xs sm:text-sm md:text-base"
                    style={{ color: theme.text }}
                >
                    Shop the Newest Arrivals
                </p>
            </div>
            {isLoading ? (
                <div className="text-center text-gray-500">Loading...</div>
            ) : latestProducts.length > 0 ? (
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 gap-y-6">
                    {latestProducts.map((item, index) => (
                        <ProductItem
                            key={index}
                            id={item.id}
                            image={item.images?.[0]}
                            name={item.name}
                            price={item.price}
                            archived={item.archive}
                        />
                    ))}
                </div>
            ) : (
                <div className="text-center text-gray-500">No new arrivals found.</div>
            )}
        </div>
    );
};

export default LatestCollection;
