import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import styles for toast
import Logo from '../../assets/logosquare.png';
import Google from '../../assets/google.png';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useTheme } from '../../context/ThemeContext'; // Import theme hook

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { loginWithEmail, signInWithGoogle, currentUser } = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasUppercase: false,
    });
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const { theme } = useTheme(); // Get the current theme
    const navigate = useNavigate();

    // Redirect to dashboard if already logged in
    useEffect(() => {
        if (currentUser) {
            navigate('/');
        }
    }, [currentUser, navigate]);

    // Validate password based on criteria
    const validatePassword = (password) => {
        const lengthValid = password.length >= 8;
        const numberValid = /\d/.test(password);
        const specialCharValid = /[!@#$%^&*]/.test(password);
        const uppercaseValid = /[A-Z]/.test(password);

        setPasswordValidations({
            length: lengthValid,
            hasNumber: numberValid,
            hasSpecialChar: specialCharValid,
            hasUppercase: uppercaseValid,
        });

        return lengthValid && numberValid && specialCharValid && uppercaseValid;
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            if (validatePassword(password)) {
                await loginWithEmail(email, password);
                toast.success("Login successful!");
            } else {
                toast.error("Please ensure your password meets all requirements.");
            }
        } catch (error) {
            toast.error("Login failed. Please check your credentials.");
        }
    };

    const handleGoogleLogin = async () => {
        try {
            await signInWithGoogle();
            toast.success("Login with Google successful!");
        } catch (error) {
            toast.error("Google login failed.");
        }
    };

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Check form validity on every change
    useEffect(() => {
        setIsFormValid(validatePassword(password));
    }, [email, password]);

    return (
        <div style={{ backgroundColor: theme.primary, color: theme.text }}>
            {/* Toast Container for notifications */}
            <ToastContainer />
            <div className="w-full flex justify-center items-center">
                {/* Two-column layout */}
                <div className="flex w-full max-w-5xl shadow-lg mt-10 rounded-xl flex-col md:flex-row">
                    {/* Logo Box */}
                    <div className="w-full md:w-1/3 flex justify-center items-center">
                        <img src={Logo} alt="Logo" className="w-full h-full rounded-l-xl" />
                    </div>

                    {/* Login Form Box */}
                    <div className="w-full md:w-2/3 flex flex-col justify-center items-center p-8">
                        <h2 className="text-2xl font-bold mb-2">Log in</h2>
                        <p className="mb-4" style={{ color: theme.text }}>
                            Don't have an account? <a href="/auth/register" style={{ color: theme.accent }}>Sign up</a>
                        </p>

                        {/* Google Login Button */}
                        <button
                            onClick={handleGoogleLogin}
                            className="btn btn-outline w-full max-w-sm flex justify-center items-center mb-4"
                            style={{ backgroundColor: theme.secondary, color: theme.text }}
                        >
                            <img
                                src={Google}
                                alt="Google"
                                className="w-6 h-6 mr-2"
                            />
                            Continue with Google
                        </button>

                        <div className="flex items-center w-full max-w-sm mb-4">
                            <hr className="flex-grow border-t" style={{ borderColor: theme.text }} />
                            <span className="mx-4" style={{ color: theme.text }}>or</span>
                            <hr className="flex-grow border-t" style={{ borderColor: theme.text }} />
                        </div>

                        {/* Login Form */}
                        <form onSubmit={handleLogin} className="w-full max-w-sm space-y-4">
                            {/* Email or Username */}
                            <div className="form-control">
                                <label className="label" style={{ color: theme.text }}>Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder='customer@staplestreetstyles.com'
                                    className="input input-bordered"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    style={{ backgroundColor: theme.secondary, color: theme.text }}
                                />
                            </div>

                            {/* Password */}
                            <div className="form-control relative">
                                <label className="label" style={{ color: theme.text }}>Password</label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    placeholder='********'
                                    className="input input-bordered pr-10"
                                    value={password}
                                    onFocus={() => setIsPasswordFocused(true)}
                                    onBlur={() => setIsPasswordFocused(false)}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    style={{ backgroundColor: theme.secondary, color: theme.text }}
                                />
                                {/* Eye Icon to Toggle Password */}
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="absolute inset-y-0 right-2 top-10 pr-3 flex items-center text-sm leading-5"
                                >
                                    {showPassword ? (
                                        <FaEyeSlash className="text-gray-400" size={24} />
                                    ) : (
                                        <FaEye className="text-gray-400" size={24} />
                                    )}
                                </button>
                            </div>

                            {/* Password Validation */}
                            {isPasswordFocused && (
                                <div className="mt-2">
                                    <ul className="text-sm">
                                        <li className={`flex items-center ${passwordValidations.length ? 'text-green-500' : 'text-red-500'}`}>
                                            {passwordValidations.length ? '✔' : '✖'} At least 8 characters
                                        </li>
                                        <li className={`flex items-center ${passwordValidations.hasNumber ? 'text-green-500' : 'text-red-500'}`}>
                                            {passwordValidations.hasNumber ? '✔' : '✖'} Contains a number
                                        </li>
                                        <li className={`flex items-center ${passwordValidations.hasSpecialChar ? 'text-green-500' : 'text-red-500'}`}>
                                            {passwordValidations.hasSpecialChar ? '✔' : '✖'} Contains a special character
                                        </li>
                                        <li className={`flex items-center ${passwordValidations.hasUppercase ? 'text-green-500' : 'text-red-500'}`}>
                                            {passwordValidations.hasUppercase ? '✔' : '✖'} Contains an uppercase letter
                                        </li>
                                    </ul>
                                </div>
                            )}

                            <p style={{ color: theme.accent }} className="text-right cursor-pointer mt-2">
                                Forgot password?
                            </p>

                            <div className="form-control mt-6">
                                <button
                                    type="submit"
                                    className="btn btn-primary w-full"
                                    disabled={!isFormValid}
                                    style={{ backgroundColor: theme.accent, color: theme.text }}
                                >
                                    Log in
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;