import React, { useState, useContext, useEffect } from 'react';
import Title from '../../components/global/Title';
import CartTotals from '../../components/cart/CartTotals';
import StripeLogo from '../../assets/stripeText.png';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { ShopContext } from '../../context/ShopContext';
import { useTheme } from '../../context/ThemeContext';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 'pk_live_51PfAPNIjSQPFE77gnqJ03ridTnqnk7SzJwsVkDDDKCVpKpJI00pzsHYycxcbceAas8kp8K5H37Z3PszRoWoIMX7s009hb8NXiO');

const PlaceOrder = () => {
    const [method, setMethod] = useState('stripe');
    const { cartItems, cartTotal, deliveryFee } = useContext(ShopContext);
    const [billingInfo, setBillingInfo] = useState({
        firstName: '',
        lastName: '',
        email: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        phone: '',
    });
    const navigate = useNavigate();
    const { theme } = useTheme();
    const { currentUser } = useAuth();
    const db = getFirestore();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBillingInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (currentUser) {
                try {
                    const userDoc = await getDoc(doc(db, 'customers', currentUser.uid));
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        setBillingInfo((prev) => ({
                            ...prev,
                            firstName: userData.firstName || '',
                            lastName: userData.lastName || '',
                            email: userData.email || '',
                            street: userData.street || '',
                            city: userData.city || '',
                            state: userData.state || '',
                            zipcode: userData.zipcode || '',
                            country: userData.country || '',
                            phone: userData.phone || '',
                        }));
                    }
                } catch (error) {
                    console.error('Error fetching user info:', error);
                }
            }
        };

        fetchUserInfo();
    }, [currentUser, db]);

    const saveShippingInfo = async () => {
        if (currentUser) {
            const customerRef = doc(db, 'customers', currentUser.uid);
            try {
                const customerDoc = await getDoc(customerRef);
                if (customerDoc.exists()) {
                    const customerData = customerDoc.data();
                    const isShippingInfoComplete = customerData.shippingInfo.street && customerData.shippingInfo.city && customerData.shippingInfo.state && customerData.shippingInfo.zipcode && customerData.shippingInfo.country;
                    if (!isShippingInfoComplete) {
                        await updateDoc(customerRef, {
                            street: billingInfo.street,
                            city: billingInfo.city,
                            state: billingInfo.state,
                            zipcode: billingInfo.zipcode,
                            country: billingInfo.country,
                            phone: billingInfo.phone,
                        });
                    }
                }
            } catch (error) {
                console.error('Error saving shipping info:', error);
            }
        }
    };

    const handleStripePayment = async () => {
        try {
            await saveShippingInfo();

            const stripe = await stripePromise;
            const response = await fetch(`https://staplestreetstyles.com/api/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    items: cartItems.map(item => ({
                        id: item.itemId,
                        quantity: item.quantity,
                    })),
                    customerId: currentUser.uid,
                    cartTotal, // Send cart total
                    deliveryFee, // Send delivery fee separately
                    shippingInfo: {
                        street: billingInfo.street,
                        city: billingInfo.city,
                        state: billingInfo.state,
                        zipcode: billingInfo.zipcode,
                        country: billingInfo.country,
                        phone: billingInfo.phone,
                    },
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to create checkout session');
            }

            const session = await response.json();
            const result = await stripe.redirectToCheckout({ sessionId: session.id });

            if (result.error) {
                console.error(result.error.message);
                navigate('/place-order');
            } else {
                navigate('/orders');
            }
        } catch (error) {
            console.error("Error during Stripe payment:", error);
            navigate('/place-order');
        }
    };

    return (
        <div className={`flex flex-col sm:flex-row justify-between gap-4 pt-5 sm:pt-14 min-h-[80vh] border-t bg-${theme.primary} text-${theme.text}`}>
            {/* Left Column - Billing Information */}
            <div className='flex flex-col gap-4 w-full sm:max-w-[480px]'>
                <div className={`text-xl sm:text-2xl my-3 text-${theme.text}`}>
                    <Title text1={'DELIVERY'} text2={'INFORMATION'} />
                </div>
                {/* Billing Info Form */}
                <div className='flex gap-3'>
                    <input
                        className={`input border border-${theme.border} rounded py-1.5 px-3.5 w-full bg-${theme.secondary} text-${theme.text}`}
                        type='text'
                        placeholder={billingInfo.firstName || 'First name'}
                        name='firstName'
                        value={billingInfo.firstName}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        className={`input border border-${theme.border} rounded py-1.5 px-3.5 w-full bg-${theme.secondary} text-${theme.text}`}
                        type='text'
                        placeholder={billingInfo.lastName || 'Last name'}
                        name='lastName'
                        value={billingInfo.lastName}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <input
                    className={`input border border-${theme.border} rounded py-1.5 px-3.5 w-full bg-${theme.secondary} text-${theme.text}`}
                    type='email'
                    placeholder={billingInfo.email || 'Email address'}
                    name='email'
                    value={billingInfo.email}
                    onChange={handleInputChange}
                    required
                />
                <input
                    className={`input border border-${theme.border} rounded py-1.5 px-3.5 w-full bg-${theme.secondary} text-${theme.text}`}
                    type='text'
                    placeholder={billingInfo.street || 'Street'}
                    name='street'
                    value={billingInfo.street}
                    onChange={handleInputChange}
                    required
                />
                <div className='flex gap-3'>
                    <input
                        className={`input border border-${theme.border} rounded py-1.5 px-3.5 w-full bg-${theme.secondary} text-${theme.text}`}
                        type='text'
                        placeholder={billingInfo.city || 'City'}
                        name='city'
                        value={billingInfo.city}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        className={`input border border-${theme.border} rounded py-1.5 px-3.5 w-full bg-${theme.secondary} text-${theme.text}`}
                        type='text'
                        placeholder={billingInfo.state || 'State'}
                        name='state'
                        value={billingInfo.state}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className='flex gap-3'>
                    <input
                        className={`input border border-${theme.border} rounded py-1.5 px-3.5 w-full bg-${theme.secondary} text-${theme.text}`}
                        type='number'
                        placeholder={billingInfo.zipcode || 'Zipcode'}
                        name='zipcode'
                        value={billingInfo.zipcode}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        className={`input border border-${theme.border} rounded py-1.5 px-3.5 w-full bg-${theme.secondary} text-${theme.text}`}
                        type='text'
                        placeholder={billingInfo.country || 'Country'}
                        name='country'
                        value={billingInfo.country}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <input
                    className={`input border border-${theme.border} rounded py-1.5 px-3.5 w-full bg-${theme.secondary} text-${theme.text}`}
                    type='number'
                    placeholder={billingInfo.phone || 'Phone'}
                    name='phone'
                    value={billingInfo.phone}
                    onChange={handleInputChange}
                    required
                />
            </div>

            {/* Right Column - Payment Method */}
            <div className='mt-8'>
                <div className='mt-8 min-w-80'>
                    <CartTotals />
                </div>
                <div className='mt-12'>
                    <Title text1={'PAYMENT'} text2={'METHOD'} />
                    <div className='flex gap-3 flex-col lg:flex-row'>
                        <div
                            className={`flex items-center gap-3 border p-2 px-3 cursor-pointer border-${theme.border} bg-${theme.secondary}`}
                            onClick={() => setMethod('stripe')}
                        >
                            <input
                                type="radio"
                                name="payment-method"
                                className="radio radio-primary"
                                checked={method === 'stripe'}
                            />
                            <img src={StripeLogo} alt='Stripe' className='h-5 mx-4' />
                        </div>
                    </div>

                    {/* Conditionally Render Payment Button */}
                    {method === 'stripe' && (
                        <div className='w-full text-center mt-8'>
                            <button
                                onClick={handleStripePayment}
                                className={`btn px-28 py-3 text-sm`}
                                style={{ backgroundColor: theme.accent, color: theme.alttext }}
                            >
                                PLACE ORDER
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PlaceOrder;
