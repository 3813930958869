import React, { useContext, useState, useEffect } from 'react';
import { auth, db, googleProvider } from '../firebase'; // Ensure firebase is imported correctly
import { signInWithPopup, signInWithEmailAndPassword, signOut } from 'firebase/auth'; // Adjust imports
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore'; // Firestore functions
import { useNavigate } from 'react-router-dom';

const AuthContext = React.createContext();

// Hook to access the AuthContext
export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [admin, setAdmin] = useState(false); // State for admin status
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Create or update customer data in Firestore
    const createOrUpdateCustomer = async (user) => {
        const docRef = doc(db, 'customers', user.uid);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            // If the user doesn't exist, create a new customer document
            await setDoc(docRef, {
                firstName: user.displayName?.split(' ')[0] || '',
                lastName: user.displayName?.split(' ')[1] || '',
                displayName: user.displayName || '',
                email: user.email,
                phone: user.phoneNumber || '',
                password: '', // Empty since Google sign-in doesn't use a password
                avatar: user.photoURL || '',
                admin: false, // Defaults to non-admin
                verified: false,
                status: true,
                likedItems: [],
                wishlist: [],
                cart: [],
                orders: [],
                transactions: [],
                invoices: [],
                billingInfo: {},
                shippingInfo: {},
                emailPrefs: {}, // Initialize empty email preferences
            });
            setAdmin(false); // New users are not admins
        } else {
            const userData = docSnap.data();
            setAdmin(userData.admin); // Set the admin status from Firestore
        }
    };

    // Function to update email preferences
    const updateEmailPreferences = async (preferences) => {
        if (currentUser) {
            const userRef = doc(db, 'customers', currentUser.uid);
            await updateDoc(userRef, {
                emailPrefs: { ...preferences }, // Update email preferences
            });
        }
    };

    // Google sign-in
    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            // Create or update customer record in Firestore
            await createOrUpdateCustomer(user);

            setCurrentUser(user);
            navigate('/dashboard');
        } catch (error) {
            console.error('Google Sign-In Error:', error);
        }
    };

    // Login with email and password
    const loginWithEmail = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await createOrUpdateCustomer(user); // Check if the user is an admin
            setCurrentUser(user);
            navigate('/dashboard');
        } catch (error) {
            console.error('Email/Password Login Error:', error);
        }
    };

    // Logout function
    const logout = async () => {
        try {
            await signOut(auth);
            setCurrentUser(null);
            setAdmin(false); // Reset admin status
            navigate('/');
        } catch (error) {
            console.error('Logout Error:', error);
        }
    };

    // Handle auth state changes
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                await createOrUpdateCustomer(user); // Update Firestore info if needed
            }
            setCurrentUser(user);
            setLoading(false);
        });

        return unsubscribe; // Cleanup listener on unmount
    }, []);

    const value = {
        currentUser,
        loginWithEmail,
        signInWithGoogle,
        logout,
        isLoggedIn: !!currentUser, // Boolean flag for logged-in status
        admin, // Admin status
        updateEmailPreferences, // Email preference updater
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children} {/* Only render children when not loading */}
        </AuthContext.Provider>
    );
}
