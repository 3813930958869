import React from 'react';
import Title from '../../components/global/Title';
import logosquare from '../../assets/logosquare.png';
import Newsletter from '../../components/newsletter/Newsletter';
import { useTheme } from '../../context/ThemeContext'; // Import the theme context

const About = () => {
    const { theme } = useTheme(); // Use theme from ThemeContext

    return (
        <div style={{ color: theme.text }}>
            <div className='text-2xl text-center pt-8 border-t' style={{ borderColor: theme.border }}>
                <Title text1={'ABOUT'} text2={'US'} />
            </div>
            <div className='my-10 flex flex-col md:flex-row gap-16'>
                {/* Conditional rendering for logo based on theme */}
                <img src={logosquare} alt='' className='w-full md:max-w-[480px] rounded-[80px]' />
                <div className='flex flex-col justify-center gap-6 md:w-2/4' style={{ color: theme.text }}>
                    <p>Welcome to Staple Street Styles, where fashion meets individuality. Every piece you find here is handpicked with care, reflecting a passion for vintage streetwear that tells a story. Our collection is filled with unique, one-of-a-kind items, each chosen for its character and quality. At Staple, we believe that clothing is more than just what you wear—it's an expression of who you are.</p>
                    <p>Whether you're looking to make a statement or embrace a timeless look, our curated selection is crafted for those who want to stand out. No two pieces are alike, ensuring that what you find here is truly yours. Explore our collection and discover the vintage treasures that define your personal style.</p>
                    <b style={{ color: theme.text }}>Our Mission</b>
                    <p>At Staple Street Styles, our mission is to celebrate individuality through fashion by offering a curated selection of handpicked, one-of-a-kind vintage pieces. We believe in the power of self-expression and strive to provide unique, timeless styles that empower our customers to stand out confidently. Our commitment is to sustainability, quality, and originality—ensuring that every item in our collection tells its own story while helping you tell yours. We aim to inspire creativity and embrace diversity, making fashion a personal, authentic experience for everyone.</p>
                </div>
            </div>
            <div className='text-xl py-4'>
                <Title text1={'WHY'} text2={'CHOOSE US'} />
            </div>
            <div className='flex flex-col md:flex-row text-sm mb-20'>
                {/* Quality Assurance Section */}
                <div className='border px-10 md:px-16 py-8 sm:py-20 flex flex-col gap-5' style={{ backgroundColor: theme.secondary }}>
                    <b style={{ color: theme.text }}>Quality Assurance:</b>
                    <p style={{ color: theme.text }}>At Staple Street Styles, quality is our top priority. Every piece in our collection is meticulously handpicked to ensure it meets the highest standards of craftsmanship, durability, and style. We offer unique, vintage items that are not only visually striking but built to last. Before an item is added to our shop, it goes through a detailed inspection to guarantee authenticity, condition, and excellence. Our dedication to quality extends to providing an exceptional shopping experience, so you can trust that when you shop with Staple Street Styles, you're receiving a one-of-a-kind piece that reflects both individuality and superior attention to detail.</p>
                </div>
                {/* Convenience Section */}
                <div className='border px-10 md:px-16 py-8 sm:py-20 flex flex-col gap-5' style={{ backgroundColor: theme.secondary }}>
                    <b style={{ color: theme.text }}>Convenience:</b>
                    <p style={{ color: theme.text }}>At Staple Street Styles, we understand the importance of convenience in your shopping experience. That's why we've made it easy to browse, select, and purchase our unique, one-of-a-kind vintage pieces from the comfort of your home. Our website is designed to be user-friendly, allowing you to seamlessly explore our curated collection, find detailed information about each item, and check out securely with just a few clicks. Whether you're shopping on your desktop or mobile device, we ensure a smooth and hassle-free experience, so you can focus on finding the perfect addition to your wardrobe with ease and confidence.</p>
                </div>
                {/* Customer Service Section */}
                <div className='border px-10 md:px-16 py-8 sm:py-20 flex flex-col gap-5' style={{ backgroundColor: theme.secondary }}>
                    <b style={{ color: theme.text }}>Exceptional Customer Service:</b>
                    <p style={{ color: theme.text }}>At Staple Street Styles, exceptional customer service is at the core of our brand. We are dedicated to ensuring that every interaction you have with us is smooth, helpful, and personalized. From the moment you browse our collection to the delivery of your one-of-a-kind item, we are here to assist you at every step. Whether you have questions about sizing, need help with your order, or require guidance on styling, our team is always ready to provide prompt and friendly support. Your satisfaction is our priority, and we strive to exceed your expectations with every experience.</p>
                </div>
            </div>
            <Newsletter />
        </div>
    );
};

export default About;