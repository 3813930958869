import React, { useState } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { db } from '../../firebase';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Newsletter = () => {
    const { theme } = useTheme();
    const [email, setEmail] = useState('');

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            const q = query(collection(db, "emails"), where("email", "==", email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                await addDoc(collection(db, "emails"), {
                    email: email,
                    verified: false,
                    updates: false,
                    promotions: false
                });
                toast.success("You've been successfully subscribed!");
                setEmail('');
            } else {
                toast.info("Email already exists in the database.");
            }
        } catch (error) {
            console.error("Error checking or adding email: ", error);
            toast.error("Something went wrong. Please try again.");
        }
    };

    return (
        <div className='text-center' style={{ color: theme.text }}>
            <p className='text-2xl font-medium' style={{ color: theme.accent }}>Stay updated</p>
            <p className='mt-3' style={{ color: theme.text }}>Stay in touch with the latest fashion trends and updates</p>
            <form onSubmit={onSubmitHandler} className='w-full sm:w-1/2 flex items-center gap-3 mx-auto my-6 border pl-3' style={{ borderColor: theme.accent }}>
                <input
                    type='email'
                    placeholder='Enter your email'
                    className='w-full sm:flex-1 outline-none input focus:outline-none focus:border-none'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ backgroundColor: theme.primary, color: theme.text }}
                    required
                />
                <button type='submit' className='bg-black text-white text-xs px-10 py-4' style={{ backgroundColor: theme.accent }}>SUBSCRIBE</button>
            </form>
        </div>
    );
};

export default Newsletter;