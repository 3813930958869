import React, { useEffect, useState } from 'react';
import { db } from '../../firebase'; // Firebase config assumed to be set up already
import { useTheme } from '../../context/ThemeContext'; // Theme context
import { doc, getDocs, updateDoc, collection } from 'firebase/firestore';

const Customers = () => {
    const { theme } = useTheme(); // Access custom theme
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch customers from Firebase
    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'customers'));
                const customerData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setCustomers(customerData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching customers:", error);
            }
        };
        fetchCustomers();
    }, []);

    // Toggle admin rights for a user
    const toggleAdmin = async (id, isAdmin) => {
        try {
            const userRef = doc(db, 'customers', id);
            await updateDoc(userRef, { admin: !isAdmin });
            setCustomers(prevCustomers =>
                prevCustomers.map(customer =>
                    customer.id === id ? { ...customer, admin: !isAdmin } : customer
                )
            );
        } catch (error) {
            console.error("Error updating admin status:", error);
        }
    };

    if (loading) {
        return (
            <div className='flex justify-center'>
                <span className="loading loading-spinner loading-lg"></span>
            </div>
        );
    }

    return (
        <div className="overflow-x-auto">
            <table className="table">
                {/* head */}
                <thead>
                    <tr style={{ backgroundColor: theme.secondary, color: theme.text }}>
                        <th>
                            <label>
                                <input type="checkbox" className="checkbox" />
                            </label>
                        </th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Admin</th>
                        <th>Orders</th>
                        <th>Bundles</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {customers.map((customer) => (
                        <tr key={customer.id} style={{ color: theme.text }}>
                            <th>
                                <label>
                                    <input type="checkbox" className="checkbox" />
                                </label>
                            </th>
                            <td>
                                <div className="flex items-center gap-3">
                                    <div className="avatar">
                                        <div className="mask mask-squircle h-12 w-12">
                                            <img
                                                src={customer.profilePicture || "https://via.placeholder.com/150"}
                                                alt={customer.firstName}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="font-bold">{customer.firstName} {customer.lastName}</div>
                                        <div className="text-sm opacity-50">{customer.location || 'Unknown'}</div>
                                    </div>
                                </div>
                            </td>
                            <td>{customer.email}</td>
                            <td>{customer.phone || 'N/A'}</td>
                            <td>
                                <button
                                    className={`btn btn-xs ${customer.admin ? 'btn-primary' : 'btn-secondary'}`}
                                    onClick={() => toggleAdmin(customer.id, customer.admin)}
                                    style={{ backgroundColor: customer.admin ? theme.success : theme.error, color: theme.alttext }}
                                >
                                    {customer.admin ? 'Admin' : 'User'}
                                </button>
                            </td>
                            <td>
                                {customer.orders ? customer.orders.length : 0}
                            </td>
                            <td>
                                {customer.bundles ? customer.bundles.length : 0}
                            </td>
                            <td>
                                <button className="btn btn-ghost btn-xs" style={{ color: theme.accent }}>
                                    Details
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Customers;
