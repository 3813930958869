import React, { useContext, useEffect } from 'react';
import { ShopContext } from '../../context/ShopContext';
import { FaSearch } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '../../context/ThemeContext'; // Import the useTheme hook

const SearchBar = () => {
    const { search, setSearch, showSearch, setShowSearch } = useContext(ShopContext);
    const location = useLocation();
    const navigate = useNavigate();
    const { theme } = useTheme(); // Get the active theme from ThemeContext

    useEffect(() => {
        // Close the search bar when navigating away from /collection
        if (location.pathname !== '/collection') {
            setShowSearch(false);
        }
        // Check if navigation passed state to open the search bar
        if (location.pathname === '/collection' && location.state?.openSearch) {
            setShowSearch(true);
        }
    }, [location, setShowSearch]);

    // Function to toggle the search bar and redirect to collection page if not already there
    const toggleSearch = () => {
        if (location.pathname !== '/collection') {
            // Redirect to /collection and pass state to open the search bar
            navigate('/collection', { state: { openSearch: true } });
        } else {
            // If already on /collection, toggle the search bar
            setShowSearch(!showSearch);
        }
    };

    return (
        <div>
            {/* Search bar for large screens with sliding animation */}
            <div className={`flex items-center transition-all duration-500 ease-in-out ${showSearch ? 'w-full' : 'w-auto -mr-6 mt-1'}`}>
                <div
                    onClick={toggleSearch}
                    className="btn btn-ghost btn-circle flex items-center justify-center"
                    style={{ backgroundColor: theme.primary, color: theme.text }} // Apply theme styles inline
                >
                    <FaSearch className="text-2xl cursor-pointer" style={{ color: theme.text }} />
                </div>
                {/* Input only visible on large screens */}
                <input
                    type='text'
                    placeholder='Search'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="flex-1 outline-none bg-transparent text-sm pl-2 hidden sm:block transition-all duration-500 ease-in-out"
                    style={{
                        width: showSearch ? '100%' : '0', // Inline styles for transition
                        color: theme.text,
                    }}
                />
            </div>

            {/* Search bar for small screens (appears below the navbar) */}
            {showSearch && (
                <div className="block sm:hidden mt-2" style={{ backgroundColor: theme.primary }}>
                    <input
                        type='text'
                        placeholder='Search'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="w-full outline-none text-sm border-b-2 pb-1"
                        style={{
                            backgroundColorHover: theme.primary,
                            color: theme.text,
                            borderBottomColor: theme.text,
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default SearchBar;
