import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function PrivateRoute({ children, adminRequired = false }) {
    const { currentUser, admin } = useAuth();

    if (!currentUser) {
        // Redirect to login if not authenticated
        return <Navigate to="/auth/login" />;
    }

    if (adminRequired && !admin) {
        // Redirect to profile if user is not admin
        return <Navigate to={`/dashboard`} />;
    }

    return children;
}

export default PrivateRoute;
