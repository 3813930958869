import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logosquare.png';
import Google from '../../assets/google.png';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '../../context/ThemeContext'; // Import theme hook

const Register = () => {
    const [customerData, setCustomerData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const { signupWithEmail, signInWithGoogle, currentUser } = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasUppercase: false,
    });
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const { theme } = useTheme(); // Get the current theme

    // Redirect to dashboard if already logged in
    useEffect(() => {
        if (currentUser) {
            navigate('/');
        }
    }, [currentUser, navigate]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomerData({
            ...customerData,
            [name]: value,
        });
    };

    // Validate password based on criteria
    const validatePassword = (password) => {
        const lengthValid = password.length >= 8;
        const numberValid = /\d/.test(password);
        const specialCharValid = /[!@#$%^&*]/.test(password);
        const uppercaseValid = /[A-Z]/.test(password);
        const passwordsAreEqual = password === customerData.confirmPassword;

        setPasswordValidations({
            length: lengthValid,
            hasNumber: numberValid,
            hasSpecialChar: specialCharValid,
            hasUppercase: uppercaseValid,
        });

        setPasswordsMatch(passwordsAreEqual);

        return lengthValid && numberValid && specialCharValid && uppercaseValid && passwordsAreEqual;
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (validatePassword(customerData.password)) {
            try {
                await signupWithEmail(customerData.email, customerData.password, customerData);
                toast.success("Registration successful!");
            } catch (error) {
                toast.error("Registration failed.");
            }
        }
    };

    const handleGoogleRegister = async () => {
        try {
            await signInWithGoogle();
            toast.success("Google registration successful!");
        } catch (error) {
            toast.error("Google registration failed.");
        }
    };

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    // Check form validity on every change
    useEffect(() => {
        setIsFormValid(validatePassword(customerData.password));
    }, [customerData]);

    // Handle showing/hiding the registration form
    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };

    return (
        <div className="flex flex-col pt-20 items-center min-h-screen" style={{ backgroundColor: theme.primary, color: theme.text }}>
            <ToastContainer />
            {/* Logo */}
            <div className="mb-4">
                <img
                    src={Logo}
                    alt="Logo"
                    className="w-20 h-20"
                />
            </div>

            <h2 className="text-2xl font-bold mb-2">Register</h2>
            <p className="mb-4" style={{ color: theme.text }}>
                Already have an account? <a href="/auth/login" style={{ color: theme.accent }}>Log in</a>
            </p>

            {/* Google Register Button */}
            <button
                onClick={handleGoogleRegister}
                className="btn btn-outline w-full max-w-sm flex justify-center items-center mb-4"
                style={{ backgroundColor: theme.secondary, color: theme.text }}
            >
                <img
                    src={Google}
                    alt="Google"
                    className="w-6 h-6 mr-2"
                />
                Continue with Google
            </button>

            {!isFormVisible && (
                <button
                    onClick={toggleFormVisibility}
                    className="btn btn-outline w-full max-w-sm flex justify-center items-center mb-4"
                    style={{ backgroundColor: theme.secondary, color: theme.text }}
                >
                    Register with Email/Password
                </button>
            )}

            {/* Show the form only when the "Register with Email/Password" button is clicked */}
            {isFormVisible && (
                <div className="w-full max-w-sm">
                    <form onSubmit={handleRegister} className="space-y-4">
                        {/* First Name */}
                        <div className="form-control">
                            <label className="label" style={{ color: theme.text }}>First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                className="input input-bordered"
                                value={customerData.firstName}
                                onChange={handleInputChange}
                                required
                                style={{ backgroundColor: theme.secondary, color: theme.text }}
                            />
                        </div>

                        {/* Last Name */}
                        <div className="form-control">
                            <label className="label" style={{ color: theme.text }}>Last Name</label>
                            <input
                                type="text"
                                name="lastName"
                                className="input input-bordered"
                                value={customerData.lastName}
                                onChange={handleInputChange}
                                required
                                style={{ backgroundColor: theme.secondary, color: theme.text }}
                            />
                        </div>

                        {/* Email */}
                        <div className="form-control">
                            <label className="label" style={{ color: theme.text }}>Email</label>
                            <input
                                type="email"
                                name="email"
                                className="input input-bordered"
                                value={customerData.email}
                                onChange={handleInputChange}
                                required
                                style={{ backgroundColor: theme.secondary, color: theme.text }}
                            />
                        </div>

                        {/* Password */}
                        <div className="form-control relative">
                            <label className="label" style={{ color: theme.text }}>Password</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                className="input input-bordered pr-10"
                                value={customerData.password}
                                onChange={handleInputChange}
                                onFocus={() => setIsPasswordFocused(true)}
                                onBlur={() => setIsPasswordFocused(false)}
                                required
                                style={{ backgroundColor: theme.secondary, color: theme.text }}
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="absolute inset-y-0 right-2 top-10 pr-3 flex items-center text-sm leading-5"
                            >
                                {showPassword ? (
                                    <FaEyeSlash className="text-gray-400" size={24} />
                                ) : (
                                    <FaEye className="text-gray-400" size={24} />
                                )}
                            </button>
                        </div>

                        {/* Show confirm password field only after password is entered */}
                        {customerData.password && (
                            <div className="form-control relative">
                                <label className="label" style={{ color: theme.text }}>
                                    Confirm Password
                                    <span className={`ml-2 ${passwordsMatch ? 'text-green-500' : 'text-red-500'}`}>
                                        {passwordsMatch ? '✔ Passwords match' : '✖ Passwords do not match'}
                                    </span>
                                </label>
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    name="confirmPassword"
                                    className="input input-bordered pr-10"
                                    value={customerData.confirmPassword}
                                    onChange={handleInputChange}
                                    required
                                    style={{ backgroundColor: theme.secondary, color: theme.text }}
                                />
                                <button
                                    type="button"
                                    onClick={toggleConfirmPasswordVisibility}
                                    className="absolute inset-y-0 right-2 top-10 pr-3 flex items-center text-sm leading-5"
                                >
                                    {showConfirmPassword ? (
                                        <FaEyeSlash className="text-gray-400" size={24} />
                                    ) : (
                                        <FaEye className="text-gray-400" size={24} />
                                    )}
                                </button>
                            </div>
                        )}

                        {/* Password Validation */}
                        {isPasswordFocused && (
                            <div className="mt-2">
                                <ul className="text-sm">
                                    <li className={`flex items-center ${passwordValidations.length ? 'text-green-500' : 'text-red-500'}`}>
                                        {passwordValidations.length ? '✔' : '✖'} At least 8 characters
                                    </li>
                                    <li className={`flex items-center ${passwordValidations.hasNumber ? 'text-green-500' : 'text-red-500'}`}>
                                        {passwordValidations.hasNumber ? '✔' : '✖'} Contains a number
                                    </li>
                                    <li className={`flex items-center ${passwordValidations.hasSpecialChar ? 'text-green-500' : 'text-red-500'}`}>
                                        {passwordValidations.hasSpecialChar ? '✔' : '✖'} Contains a special character
                                    </li>
                                    <li className={`flex items-center ${passwordValidations.hasUppercase ? 'text-green-500' : 'text-red-500'}`}>
                                        {passwordValidations.hasUppercase ? '✔' : '✖'} Contains an uppercase letter
                                    </li>
                                </ul>
                            </div>
                        )}

                        <div className="form-control mt-6">
                            <button
                                type="submit"
                                className="btn btn-primary w-full"
                                disabled={!isFormValid}
                                style={{ backgroundColor: theme.accent, color: theme.text }}
                            >
                                Register with Email
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Register;