import React, { createContext, useState, useEffect, useContext } from 'react';
import { themeChange } from 'theme-change'; // DaisyUI's theme switching library

// Create the context
const ThemeContext = createContext();

// Custom hook to use the ThemeContext
export const useTheme = () => useContext(ThemeContext);

// Define updated theme configurations
const themes = {
    light: {
        primary: '#F5F5F5', // Light background
        secondary: '#E5E7EB', // Light gray
        accent: '#FF0033', // Bright red for contrast
        text: '#1F2937', // Dark gray for text
        alttext: '#FFFFFF', // White for contrast on darker buttons
        border: '#D1D5DB', // Light gray borders
        hover: '#F3F4F6', // Soft hover effect
        shadow: '#A0AEC0', // Subtle shadow
        overlay: 'rgba(0, 0, 0, 0.1)', // Light overlay for modals
        success: '#38A169', // Green for success
        warning: '#D69E2E', // Yellow for warning
        error: '#E53E3E', // Red for errors
        highlight: '#FFDEDE', // Light pink highlight

        // Button Variants
        buttonPrimary: '#FF0033', // Bright red primary button
        buttonPrimaryText: '#FFFFFF', // White text for contrast
        buttonSecondary: '#E5E7EB', // Light gray secondary button
        buttonSecondaryText: '#1F2937', // Dark text for contrast
        buttonTertiary: '#F3F4F6', // Very light tertiary button
        buttonTertiaryText: '#FF0033', // Red text for contrast on light button
    },
    dark: {
        primary: '#121212', // Dark background
        secondary: '#1F2937', // Dark gray elements
        accent: '#007BFF', // Bright blue for call-to-action
        text: '#F1F5F9', // Light text for readability
        alttext: '#F1F5F9', // Light text for buttons
        border: '#4B5563', // Darker gray borders
        hover: '#2D3748', // Hover effect for interactive elements
        shadow: '#1A202C', // Darker shadow for depth
        overlay: 'rgba(0, 0, 0, 0.7)', // Dark overlay for modals
        success: '#48BB78', // Success indicator (green)
        warning: '#ED8936', // Warning indicator (orange)
        error: '#F56565', // Error indicator (red)
        highlight: '#3B82F6', // Highlight (blue)

        // Button Variants
        buttonPrimary: '#007BFF', // Bright blue button
        buttonPrimaryText: '#FFFFFF', // White text for contrast
        buttonSecondary: '#1F2937', // Dark secondary button
        buttonSecondaryText: '#F1F5F9', // Light text for contrast
        buttonTertiary: '#2D3748', // Dark gray tertiary button
        buttonTertiaryText: '#007BFF', // Bright blue text for contrast
    },
    halloween: {
        primary: '#0A0A0A', // Black-like background
        secondary: '#7F00FF', // Fiery purple for contrast
        accent: '#E25822', // Orange for spooky elements
        text: '#EAEAEA', // Light gray for text on dark backgrounds
        alttext: '#0A0A0A', // Dark text for light backgrounds
        border: '#4B0082', // Dark purple borders
        hover: '#2A2A2A', // Dark hover effect
        shadow: '#8B0000', // Blood red shadow
        overlay: 'rgba(0, 0, 0, 0.85)', // Darker overlay for modals
        success: '#00FF7F', // Neon green for success
        warning: '#FFD700', // Bright gold for warnings
        error: '#FF4500', // Fiery red-orange for errors
        highlight: '#FF7518', // Bright orange for highlights

        // Button Variants
        buttonPrimary: '#7F00FF', // Purple primary button
        buttonPrimaryText: '#FFFFFF', // White text for contrast
        buttonSecondary: '#E25822', // Fiery orange secondary button
        buttonSecondaryText: '#0A0A0A', // Dark text for contrast on orange button
        buttonTertiary: '#FFD700', // Bright gold tertiary button
        buttonTertiaryText: '#7F00FF', // Purple text for contrast
    },
};

export const ThemeProvider = ({ children }) => {
    // Load the theme from localStorage or default to 'light'
    const [themeName, setThemeName] = useState(localStorage.getItem('theme') || 'light');
    const [theme, setTheme] = useState(themes[themeName] || themes.light); // Default to 'light' if theme not found

    // Sync theme changes with localStorage and apply the class to the root <html> element
    useEffect(() => {
        const rootElement = document.documentElement;
        // Remove previous theme classes
        Object.keys(themes).forEach(t => rootElement.classList.remove(t));
        rootElement.classList.add(themeName); // Add the selected theme class to <html>
        localStorage.setItem('theme', themeName); // Persist the theme in localStorage
        setTheme(themes[themeName] || themes.light); // Update theme values or fallback to 'light'

        // DaisyUI theme change support
        themeChange(false); // Initialize theme-change to handle DaisyUI themes
    }, [themeName]);

    // Method to change theme
    const changeTheme = (newTheme) => {
        setThemeName(newTheme);
        console.log('Theme changed to:', newTheme);
    };

    return (
        <ThemeContext.Provider value={{ theme, changeTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};
