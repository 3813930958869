import React, { useState, useEffect } from 'react';
import { useTheme } from '../../context/ThemeContext';

const PreferencesSettings = () => {
    const { themeName, changeTheme } = useTheme(); // Get the themeName and changeTheme function from context
    const [selectedTheme, setSelectedTheme] = useState(themeName); // Sync state with the current theme

    // Sync the selected theme with context when the context theme changes
    useEffect(() => {
        setSelectedTheme(themeName); // Sync with the current theme
    }, [themeName]);

    // Handle theme change
    const handleThemeChange = (e) => {
        const newTheme = e.target.value;
        setSelectedTheme(newTheme); // Update local state
        changeTheme(newTheme); // Update the global theme in context
    };

    return (
        <div
            className="card shadow-xl p-6"
            style={{ backgroundColor: 'var(--b1)', color: 'var(--bc)' }} // DaisyUI automatically applies variables for current theme
        >
            <h2 className="text-2xl font-bold mb-4">Preferences</h2>

            <div className="form-control mb-6">
                <label className="label font-semibold">Select Theme</label>
                <select
                    value={selectedTheme}
                    onChange={handleThemeChange}
                    className="select select-bordered w-full"
                    style={{ backgroundColor: 'var(--b2)', color: 'var(--bc)' }}
                >
                    <option disabled>Select Theme</option>
                    <option value="light">Light</option>
                    <option value="dark">Dark</option>
                    <option value="halloween">Halloween</option>
                    <option value="fall">Fall</option>
                </select>
            </div>
        </div>
    );
};

export default PreferencesSettings;
