import React, { useState, useContext, useEffect } from 'react';
import { ShopContext } from '../../context/ShopContext';
import { getAuth } from 'firebase/auth';
import { db, storage } from '../../firebase';
import { collection, addDoc, updateDoc, doc, onSnapshot, writeBatch, deleteDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject, listAll } from 'firebase/storage';
import ProductTable from './products/ProductTable';
import AddProductForm from './products/AddProductForm';
import { ImageRow } from './products/ImageRow';
import ColorTagsStyles from './products/ColorTagsStyles';
import { v4 as uuidv4 } from 'uuid'; // Import the uuid function
import { useTheme } from '../../context/ThemeContext'; // Import your ThemeContext

const Products = () => {
    const { theme } = useTheme(); // Get theme colors
    const { currency, colorClassMap } = useContext(ShopContext);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [newProduct, setNewProduct] = useState({
        name: '',
        price: '',
        category: '',
        subCategory: '',
        condition: '',
        size: '',
        colors: [],
        tags: [],
        styles: [],
        weight: '',
        description: '',
        cart: [],
        wishlist: [],
        reviews: [],
        likes: [],
        sold: false,
        archive: false,
        listed_at: '',
        updated_at: '',
    });

    const [isArchived, setIsArchived] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [localImages, setLocalImages] = useState([]); // For previewing images before upload
    const [activeImage, setActiveImage] = useState(''); // To handle the active image
    const [imagesToDelete, setImagesToDelete] = useState([]); // Track images marked for deletion

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'products'), (snapshot) => {
            const productList = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setProducts(productList);
        });

        return () => unsubscribe();
    }, []);

    const toggleProductSelection = (productId) => {
        setSelectedProducts(
            selectedProducts.includes(productId)
                ? selectedProducts.filter(id => id !== productId)
                : [...selectedProducts, productId]
        );
    };

    const toggleAllProducts = () => {
        setSelectedProducts(
            selectedProducts.length === products.length
                ? []
                : products.map(product => product.id)
        );
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewProduct((prevProduct) => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    const resetForm = () => {
        setNewProduct({
            name: '',
            price: '',
            category: '',
            subCategory: '',
            condition: '',
            size: '',
            colors: [],
            tags: [],
            styles: [],
            weight: '',
            description: '',
            cart: [],
            wishlist: [],
            reviews: [],
            likes: [],
            sold: false,
            archive: false,
            listed_at: '',
            updated_at: '',
        });
        setLocalImages([]);
        setActiveImage('');
        setIsArchived(false);
        setIsEditMode(false);
        setImagesToDelete([]);
    };

    const handleAddProduct = async () => {
        try {
            const productData = {
                ...newProduct,
                archive: isArchived,
                listed_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                images: [], // Initialize images field
            };

            // Add the product to Firestore and retrieve the productId
            const productRef = await addDoc(collection(db, 'products'), productData);
            const productId = productRef.id;

            // Upload images and save the URLs with order to Firestore
            if (localImages && localImages.length > 0) {
                const uploadedImages = await handleImageUploads(productId, localImages);
                await updateDoc(productRef, { images: uploadedImages });
            }

            resetForm();
        } catch (error) {
            console.error('Error adding product: ', error);
        }
    };

    const handleImageUploads = async (productId, images) => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
            console.error('User is not authenticated');
            return [];
        }

        const uid = user.uid || uuidv4(); // Use user uid or fallback to UUID if needed
        const uploadedImages = [];

        try {
            // Loop through images and upload/rename each with a unique identifier
            for (let index = 0; index < images.length; index++) {
                const image = images[index];
                const fileIndex = index + 1; // Increment to start from 1
                const uniqueFileName = `${uid}-${fileIndex}`; // Format: uid-1, uid-2, etc.
                const storageRef = ref(storage, `products/${productId}/images/${uniqueFileName}`); // Storage path

                // If image already exists (local image preview), skip re-upload
                if (image.preview.startsWith('https://')) {
                    uploadedImages.push({ url: image.preview, order: fileIndex });
                    continue; // Skip this image, already in storage
                }

                const uploadTask = uploadBytesResumable(storageRef, image.file); // Upload the image

                await new Promise((resolve, reject) => {
                    uploadTask.on(
                        'state_changed',
                        null,
                        reject,
                        async () => {
                            const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref); // Get download URL
                            uploadedImages.push({ url: downloadUrl, order: fileIndex }); // Save image URL and order
                            resolve();
                        }
                    );
                });

                console.log(`Image ${uniqueFileName} uploaded to /products/${productId}/images/`);
            }
        } catch (error) {
            console.error('Error uploading images: ', error);
        }

        return uploadedImages; // Return list of uploaded images with order
    };

    const moveImagesToArchive = async (productId) => {
        try {
            const timestamp = new Date().toISOString().replace(/:/g, '-'); // Use timestamp to ensure uniqueness
            const storageRef = ref(storage, `products/${productId}/images/`);
            const listResult = await listAll(storageRef);

            // Move each image to the archive folder
            for (const itemRef of listResult.items) {
                const fileName = itemRef.name; // Get the file name
                const archiveRef = ref(storage, `products/${productId}/archived/${timestamp}/${fileName}`); // Archive path

                // Download the existing image
                const imageUrl = await getDownloadURL(itemRef);
                const response = await fetch(imageUrl);
                const blob = await response.blob();

                // Upload to the archive folder
                await uploadBytesResumable(archiveRef, blob);

                // Delete the original image
                await deleteObject(itemRef);

                console.log(`Image ${fileName} archived to /products/${productId}/archived/${timestamp}/`);
            }
        } catch (error) {
            console.error('Error moving images to archive:', error);
        }
    };

    const fetchImagesFromStorage = async (productId) => {
        try {
            if (!productId) return [];
            const productRef = doc(db, 'products', productId);
            const productSnapshot = await getDoc(productRef);
            const productData = productSnapshot.data();
            const images = productData.images || [];

            // Fetch URLs in the stored order
            const imagesWithPreview = images.map((image) => ({
                file: { name: image.url }, // Placeholder for the file object
                preview: image.url,
                order: image.order, // Ensure order is maintained
            }));

            return imagesWithPreview; // Return images with preview
        } catch (error) {
            console.error('Error fetching images from storage:', error);
            return [];
        }
    };

    const handleEditProduct = async () => {
        try {
            const productData = {
                ...newProduct,
                archive: isArchived,
                updated_at: new Date().toISOString(),
            };

            const productRef = doc(db, 'products', newProduct.id);

            // Handle image deletions and renaming of remaining images
            if (imagesToDelete.length > 0) {
                const remainingImages = await handleDeletedImages(newProduct.id, localImages);
                setLocalImages(remainingImages); // Update local state with remaining images
            }

            // Upload or rename remaining images in the correct order
            if (localImages.length > 0) {
                const updatedImages = await handleImageUploads(newProduct.id, localImages);
                await updateDoc(productRef, { ...productData, images: updatedImages });
            } else {
                // If no images are left, just update the product data
                await updateDoc(productRef, productData);
            }

            resetForm();
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    const handleDeletedImages = async (productId, currentImages) => {
        try {
            const remainingImages = currentImages.filter(
                (image) => !imagesToDelete.includes(image.preview)
            );

            // Delete images marked for deletion from Firebase Storage
            const deletePromises = imagesToDelete.map(async (deletedImageUrl) => {
                const fileName = deletedImageUrl.split('/').pop();
                const storageRef = ref(storage, `products/${productId}/images/${fileName}`);
                await deleteObject(storageRef);
            });

            await Promise.all(deletePromises);
            console.log('Deleted images removed from Firebase Storage.');

            return remainingImages; // Return the images that were not deleted
        } catch (error) {
            console.error('Error deleting images:', error);
            return currentImages; // Return the original set if there's an error
        }
    };

    const handleViewProduct = async (product) => {
        try {
            setNewProduct(product);
            if (product.id) {
                // Fetch images from storage and load them into local state
                const fetchedImages = await fetchImagesFromStorage(product.id);
                setLocalImages(fetchedImages); // Ensure localImages is set correctly
            }
            setIsArchived(product.archive || false);
            setIsEditMode(true);
        } catch (error) {
            console.error('Error viewing product:', error);
        }
    };

    const handleArchiveProduct = async (productIds) => {
        try {
            const batch = writeBatch(db);
            productIds.forEach(id => {
                const productRef = doc(db, 'products', id);
                batch.update(productRef, { archive: true }); // Set archive field to true
            });
            await batch.commit();
            console.log('Products archived:', productIds);

            // Update local product state
            setProducts((prevProducts) =>
                prevProducts.map((product) =>
                    productIds.includes(product.id)
                        ? { ...product, archive: true } // Update the archive status locally
                        : product
                )
            );
        } catch (error) {
            console.error('Error archiving products:', error);
        }
    };

    const handleDeleteProduct = async (productIds) => {
        try {
            for (const id of productIds) {
                const storageRef = ref(storage, `products/${id}/images/`);
                await listAll(storageRef).then((res) => {
                    res.items.forEach((itemRef) => {
                        deleteObject(itemRef)
                            .then(() => {
                                console.log('Image deleted from Firebase');
                            })
                            .catch((error) => {
                                console.error('Error deleting image:', error);
                            });
                    });
                });

                const productRef = doc(db, 'products', id);
                await deleteDoc(productRef);
                console.log(`Product with ID ${id} deleted.`);
            }
            setSelectedProducts([]);
        } catch (error) {
            console.error('Error deleting products:', error);
        }
    };

    const handleColorInput = (e) => {
        if (e.key === 'Enter' && e.target.value.trim()) {
            const color = e.target.value.trim().toLowerCase();
            if (colorClassMap[color] && !newProduct.colors.includes(color)) {
                setNewProduct((prevProduct) => ({
                    ...prevProduct,
                    colors: [...prevProduct.colors, color],
                }));
            }
            e.target.value = '';
        }
    };

    const handleTagInput = (e) => {
        if (e.key === 'Enter' && e.target.value.trim()) {
            const tag = e.target.value.trim().toLowerCase();
            if (!newProduct.tags.includes(tag)) {
                setNewProduct((prevProduct) => ({
                    ...prevProduct,
                    tags: [...prevProduct.tags, tag],
                }));
            }
            e.target.value = '';
        }
    };

    const handleStyleInput = (e) => {
        if (e.key === 'Enter' && e.target.value.trim()) {
            const style = e.target.value.trim().toLowerCase();
            if (!newProduct.styles.includes(style)) {
                setNewProduct((prevProduct) => ({
                    ...prevProduct,
                    styles: [...prevProduct.styles, style],
                }));
            }
            e.target.value = '';
        }
    };

    const handleDeleteColor = (colorToDelete) => {
        setNewProduct((prevProduct) => ({
            ...prevProduct,
            colors: prevProduct.colors.filter(color => color !== colorToDelete),
        }));
    };

    const handleDeleteTag = (tagToDelete) => {
        setNewProduct((prevProduct) => ({
            ...prevProduct,
            tags: prevProduct.tags.filter(tag => tag !== tagToDelete),
        }));
    };

    const handleDeleteStyle = (styleToDelete) => {
        setNewProduct((prevProduct) => ({
            ...prevProduct,
            styles: prevProduct.styles.filter(style => style !== styleToDelete),
        }));
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const previewImages = files.map((file) => ({
            file,
            preview: URL.createObjectURL(file),
        }));

        // Add new images at the end of the list and set active image
        setLocalImages([...localImages, ...previewImages]);
        setActiveImage(previewImages[0]?.preview); // Set the first new image as active
    };

    const handleDeleteImage = (imageIndex) => {
        const imageToDelete = localImages[imageIndex];
        const updatedImages = localImages.filter((_, index) => index !== imageIndex);
        setLocalImages(updatedImages);

        if (activeImage === imageToDelete.preview) {
            setActiveImage(updatedImages[0]?.preview || '');
        }

        setImagesToDelete((prev) => [...prev, imageToDelete.preview]);
    };

    useEffect(() => {
        if (localImages.length > 0) {
            setActiveImage(localImages[0].preview); // Set the first image as the active image
        }
    }, [localImages]); // This useEffect will trigger whenever localImages is updated

    return (
        <div className='mt-5 border-t' style={{ borderColor: theme.border, backgroundColor: theme.primary }}>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                <div className='flex flex-col w-full mt-2 pt-2'>
                    <ProductTable
                        products={products}
                        selectedProducts={selectedProducts}
                        toggleProductSelection={toggleProductSelection}
                        toggleAllProducts={toggleAllProducts}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        currency={currency}
                        handleViewProduct={handleViewProduct}
                        handleArchiveProduct={handleArchiveProduct}
                        handleDeleteProduct={handleDeleteProduct}
                    />
                </div>

                <div className='flex flex-col gap-6 p-5'>
                    <ImageRow
                        newProduct={newProduct}
                        handleImageChange={handleImageChange}
                        handleDeleteImage={handleDeleteImage}
                        setNewProduct={setNewProduct}
                        activeImage={activeImage}
                        setActiveImage={setActiveImage}
                        localImages={localImages}
                        setLocalImages={setLocalImages}
                        imagesToDelete={imagesToDelete}
                        setImagesToDelete={setImagesToDelete}
                    />
                    <div className='flex flex-col sm:flex-row items-center gap-5'>
                        <AddProductForm
                            newProduct={newProduct}
                            handleInputChange={handleInputChange}
                            handleColorInput={handleColorInput}
                            handleTagInput={handleTagInput}
                            handleStyleInput={handleStyleInput}
                            handleAddProduct={handleAddProduct}
                            handleEditProduct={handleEditProduct}
                            isEditMode={isEditMode}
                        />
                        <ColorTagsStyles
                            newProduct={newProduct}
                            handleDeleteColor={handleDeleteColor}
                            handleColorInput={handleColorInput}
                            handleDeleteTag={handleDeleteTag}
                            handleTagInput={handleTagInput}
                            handleDeleteStyle={handleDeleteStyle}
                            handleStyleInput={handleStyleInput}
                            colorClassMap={colorClassMap}
                            isArchived={isArchived}
                            setIsArchived={setIsArchived}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Products;
