import React from 'react';
import logolight from '../../assets/logolight.png';
import logodark from '../../assets/logodark.png';
import { useTheme } from '../../context/ThemeContext'; // Use theme
import { FaInstagram, FaLinkedin, FaTiktok } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
    const { theme } = useTheme();
    const logo = theme.primary === '#FFFFFF' ? logolight : logodark; // Choose logo based on theme

    return (
        <div style={{ color: theme.text }}>
            <div className='flex flex-col sm:grid grid-cols-[3fr_1fr_1fr] gap-14 my-10 mt-40 text-sm'>
                <div>
                    <p className='text-xl font-medium mb-5'>Staple Street Styles</p>
                    <ul className='flex flex-col gap-1' style={{ color: theme.accent }}>
                        <li><a href='/'>Home</a></li>
                        <li><a href='/collection'>Collection</a></li>
                        <li><a href='/bundles'>Bundles</a></li>
                        <li><a href='/about'>About</a></li>
                        <li><a href='/contact'>Contact</a></li>
                    </ul>
                </div>
                <div>
                    <p className='text-xl font-medium mb-5'>Get in touch</p>
                    <ul className='flex flex-col gap-1' style={{ color: theme.accent }}>
                        <div className='flex flex-row gap-1 justify-left items-center'>
                            <a href='https://instagram.com/staplestreetstyles' target='_blank'><FaInstagram size={20} /></a>
                            <a href='https://tiktok.com/@staplestreetstyles' target='_blank'><FaTiktok size={20} /></a>
                            <a href='https://x.com/stapleststyles' target='_blank'><FaXTwitter size={20} /></a>
                            <a href='https://www.linkedin.com/in/scarlet-rose-a7ab7a325/' target='_blank'><FaLinkedin size={20} /></a>
                        </div>
                        <li>contact@staplestreetstyles.com</li>
                    </ul>
                </div>
            </div>
            <div>
                <hr style={{ borderColor: theme.accent }} />
                <p className='py-5 text-sm text-center'>Copyright © 2024 Staple Street Styles. All rights reserved.</p>
            </div>
        </div>
    );
};

export default Footer;
