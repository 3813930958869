import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../../components/admin/Dashboard';
import Navbar from '../../components/admin/Navbar';
import Products from '../../components/admin/Products';
import Customers from '../../components/admin/Customers';
import Orders from '../../components/admin/Orders';

const Admin = () => {
    return (
        <div className="admin px-4 sm:px-[5vw] md:px-[7vw] lg:px-[9vw]">
            <main className='content'>
                <Navbar />
                <Routes>
                    <Route path='/' element={<Dashboard />} />
                    <Route path='products' element={<Products />} />
                    <Route path='customers' element={<Customers />} />
                    <Route path='orders' element={<Orders />} />
                </Routes>
            </main>
        </div>
    );
};

export default Admin;