import React from 'react';

const ColorTagsStyles = ({
    newProduct,
    handleDeleteColor,
    handleColorInput,
    handleDeleteTag,
    handleTagInput,
    handleDeleteStyle,
    handleStyleInput,
    colorClassMap,
    isArchived,
    setIsArchived
}) => (
    <>
        <div className="flex flex-col gap-4 w-fit">
            <label className="label text-xl font-bold">Add Colors, Tags, Styles</label>

            {/* Colors Section */}
            <div className="gap-2 w-full max-w-xs">
                <div className="flex flex-wrap gap-2 mb-2">
                    {newProduct.colors.length > 0
                        ? newProduct.colors.map((color, index) => (
                            <div
                                key={index}
                                className={`${colorClassMap[color]} badge badge-lg cursor-pointer relative`}
                                style={{ color: '#fff' }}
                                onClick={() => handleDeleteColor(color)}
                            ></div>
                        ))
                        : <div className="text-sm text-gray-500">No colors added yet</div>}
                </div>
                <input
                    type="text"
                    placeholder="Add a color"
                    className="input input-bordered w-full max-w-xs mr-4"
                    onKeyDown={handleColorInput}
                />
            </div>

            {/* Tags Section */}
            <div className="gap-2 w-full max-w-xs">
                <div className="flex flex-wrap gap-2 mb-2">
                    {newProduct.tags.length > 0
                        ? newProduct.tags.map((tag, index) => (
                            <div
                                key={index}
                                className="badge badge-neutral badge-lg flex flex-row cursor-pointer justify-center items-center gap-2 py-2 px-4"
                                onClick={() => handleDeleteTag(tag)}
                            >
                                <span>{tag}</span>
                                <span>x</span>
                            </div>
                        ))
                        : <div className="text-sm text-gray-500">No tags added yet</div>}
                </div>
                <input
                    type="text"
                    placeholder="Add a tag"
                    className="input input-bordered w-full max-w-xs"
                    onKeyDown={handleTagInput}
                />
            </div>

            {/* Styles Section */}
            <div className="gap-2 w-full max-w-xs">
                <div className="flex flex-wrap gap-2 mb-2">
                    {newProduct.styles.length > 0
                        ? newProduct.styles.map((style, index) => (
                            <div
                                key={index}
                                className="badge badge-neutral badge-lg flex flex-row cursor-pointer justify-center items-center gap-2 py-2 px-4"
                                onClick={() => handleDeleteStyle(style)}
                            >
                                <span>{style}</span>
                                <span>x</span>
                            </div>
                        ))
                        : <div className="text-sm text-gray-500">No styles added yet</div>}
                </div>
                <input
                    type="text"
                    placeholder="Add a style"
                    className="input input-bordered w-full max-w-xs"
                    onKeyDown={handleStyleInput}
                />
            </div>

            {/* Archived Toggle Section */}
            <div className="flex flex-row justify-center items-center gap-4 mt-4">
                <label className="label text-xl font-bold">Archived</label>
                <input
                    type="checkbox"
                    className="toggle translate-y-0.2"
                    checked={isArchived} // Bind toggle to isArchived state
                    onChange={() => setIsArchived(!isArchived)} // Toggle the archive state
                />
            </div>
        </div>
    </>
);

export default ColorTagsStyles;
