import React from 'react';
import Hero from '../../components/hero/Hero';
import LatestCollection from '../../components/collections/LatestCollection';
import Policy from '../../components/policy/Policy';
import Newsletter from '../../components/newsletter/Newsletter';
import PopularCollection from '../../components/collections/PopularCollection';
import EmailPopup from '../../events/EmailPopup';
import { useTheme } from '../../context/ThemeContext';

const Home = () => {
    const { theme } = useTheme();
    return (
        <div>
            <Hero />
            <LatestCollection />
            <PopularCollection />
            {/* <Policy /> */}
            <br /><br />
            <Newsletter />
            {/* <EmailPopup /> */}
        </div>
    );
};

export default Home;
