import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext'; // For user authentication
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase'; // Firebase setup
import { useTheme } from '../../context/ThemeContext'; // For theme context

const ProfileSettings = () => {
    const { currentUser } = useAuth(); // Get the authenticated user
    const { theme } = useTheme(); // Get active theme
    const [emailPrefs, setEmailPrefs] = useState({
        updates: false,
        promotions: false,
    });
    const [loading, setLoading] = useState(true);

    // Load email preferences from Firestore
    useEffect(() => {
        const loadEmailPrefs = async () => {
            if (currentUser) {
                const docRef = doc(db, 'customers', currentUser.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setEmailPrefs(data.emailPrefs || { updates: false, promotions: false });
                }
            }
            setLoading(false);
        };

        loadEmailPrefs();
    }, [currentUser]);

    // Handle email preferences update
    const handleEmailPrefChange = async (e) => {
        const { name, checked } = e.target;
        const updatedPrefs = { ...emailPrefs, [name]: checked };
        setEmailPrefs(updatedPrefs);

        if (currentUser) {
            const userRef = doc(db, 'customers', currentUser.uid);
            await updateDoc(userRef, {
                emailPrefs: updatedPrefs,
            });
        }
    };

    if (loading) {
        return <div className="text-center">Loading...</div>;
    }

    if (!currentUser) {
        return (
            <div className="card shadow-xl p-6" style={{ backgroundColor: theme.primary, color: theme.text }}>
                <p>Please sign in to access profile settings.</p>
            </div>
        );
    }

    return (
        <div className="card shadow-xl p-6" style={{ backgroundColor: theme.primary, color: theme.text }}>
            <h2 className="text-2xl font-bold mb-4">Profile Settings</h2>

            {/* User Details */}
            <div className="form-control mb-6">
                <label className="label font-semibold">Name</label>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    style={{ backgroundColor: theme.secondary, color: theme.text }}
                    value={currentUser.displayName || ''}
                    readOnly
                />
            </div>

            <div className="form-control mb-6">
                <label className="label font-semibold">Email</label>
                <input
                    type="email"
                    className="input input-bordered w-full"
                    style={{ backgroundColor: theme.secondary, color: theme.text }}
                    value={currentUser.email || ''}
                    readOnly
                />
            </div>

            {/* Email Preferences */}
            <div className="form-control mb-6">
                <label className="label font-semibold">Email Preferences</label>
                <div className="flex flex-col gap-2">
                    <label className="cursor-pointer flex items-center space-x-2">
                        <input
                            type="checkbox"
                            name="updates"
                            checked={emailPrefs.updates}
                            onChange={handleEmailPrefChange}
                            className="checkbox checkbox-primary"
                        />
                        <span>Receive Updates</span>
                    </label>
                    <label className="cursor-pointer flex items-center space-x-2">
                        <input
                            type="checkbox"
                            name="promotions"
                            checked={emailPrefs.promotions}
                            onChange={handleEmailPrefChange}
                            className="checkbox checkbox-primary"
                        />
                        <span>Receive Promotions</span>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default ProfileSettings;
