import React, { useContext, useEffect, useState } from 'react';
import { ShopContext } from '../../context/ShopContext';
import Title from '../../components/global/Title';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../context/ThemeContext';

const Orders = () => {
    const { currency } = useContext(ShopContext);
    const { currentUser } = useAuth();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const { theme } = useTheme();
    const db = getFirestore();

    // Function to fetch product information based on productId
    const fetchProductInfo = async (productId) => {
        try {
            const productRef = doc(db, 'products', productId);
            const productSnap = await getDoc(productRef);
            if (productSnap.exists()) {
                const productData = productSnap.data();
                const firstImage = productData.images && productData.images[0] ? productData.images[0].url : "/placeholder.png";
                return {
                    name: productData.name || "Unnamed Product",
                    imageUrl: firstImage,
                    price: productData.price || "N/A"
                };
            } else {
                console.warn(`Product with ID ${productId} not found.`);
                return {
                    name: "Product not found",
                    imageUrl: "/placeholder.png",
                    price: "N/A"
                };
            }
        } catch (error) {
            console.error(`Error fetching product with ID ${productId}:`, error);
            return {
                name: "Error fetching product",
                imageUrl: "/placeholder.png",
                price: "N/A"
            };
        }
    };

    useEffect(() => {
        const fetchOrders = async () => {
            if (currentUser) {
                try {
                    // Start loading
                    setLoading(true);

                    // Get the customer's orders array from the 'customers' collection
                    const customerRef = doc(db, 'customers', currentUser.uid);
                    const customerSnap = await getDoc(customerRef);

                    if (customerSnap.exists()) {
                        const customerData = customerSnap.data();
                        const orderIds = customerData.orders || []; // Get the order IDs array

                        if (orderIds.length === 0) {
                            console.warn("No orders found for this customer.");
                            setLoading(false);
                            return;
                        }

                        // Fetch each order by its ID from the 'orders' collection
                        const fetchedOrders = [];
                        for (let orderId of orderIds) {
                            const orderRef = doc(db, 'orders', orderId);
                            const orderSnap = await getDoc(orderRef);

                            if (orderSnap.exists()) {
                                const orderData = orderSnap.data();

                                // Fetch product info using the helper function
                                const productInfo = await fetchProductInfo(orderData.productId);

                                // Merge product data with order data
                                fetchedOrders.push({
                                    id: orderSnap.id,
                                    ...orderData,
                                    productName: productInfo.name,
                                    productImage: productInfo.imageUrl,
                                    productPrice: productInfo.price
                                });
                            } else {
                                console.warn(`Order with ID ${orderId} not found.`);
                            }
                        }

                        // Set the fetched orders to the state
                        if (fetchedOrders.length > 0) {
                            setOrders(fetchedOrders);
                        } else {
                            console.warn("No valid orders were found.");
                        }
                    } else {
                        console.warn('Customer data not found.');
                    }

                    // Stop loading once the data is fetched
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching orders:', error);
                    setLoading(false); // Stop loading if there’s an error
                }
            } else {
                console.warn('No current user found.');
                setLoading(false); // Stop loading if no user found
            }
        };

        fetchOrders();
    }, [currentUser, db]);

    // Helper function to format Firestore timestamps
    const formatDate = (timestamp) => {
        if (timestamp) {
            const date = new Date(timestamp); // Convert ISO 8601 string to JavaScript Date object
            return date.toLocaleDateString(); // You can modify this to include time if needed
        }
        return 'N/A'; // Return 'N/A' if the timestamp is invalid
    };

    return (
        <div className='border-t pt-16'>
            <div className='text-2xl'>
                <Title text1={'MY'} text2={'ORDERS'} />
            </div>
            <div>
                {
                    loading ? (
                        <div className="flex justify-center items-center py-20">
                            <span className="loading loading-spinner loading-lg"></span>
                        </div>
                    ) : orders.length > 0 ? (
                        orders.map((order, index) => (
                            <div key={index} className='py-4 border-t border-b text-gray-300 flex flex-col md:flex-row md:items-start md:justify-between gap-4'>
                                <div className='flex items-start gap-6 text-sm'>
                                    <img src={order.productImage} alt={order.productName} className='w-16 sm:w-20' />
                                    <div className='flex flex-col'>
                                        {/* Product Name on its own line */}
                                        <p style={{ color: theme.text }} className='sm:text-base font-medium'>{order.productName ? order.productName : "No name available"}</p>
                                        <div className='flex items-center gap-3 mt-2 text-base text-gray-300'>
                                            <p style={{ color: theme.text }} className='text-lg'>{currency}{order.productPrice}</p>
                                        </div>
                                        <p style={{ color: theme.text }} className='mt'>Date: <span style={{ color: theme.text }} className='text-gray-400'>{formatDate(order.timeOfPurchase)}</span></p>
                                    </div>
                                </div>
                                <div className='md:w-1/2 flex justify-between'>
                                    <div className='flex items-center gap-2'>
                                        {/* Add a status indicator */}
                                        {order.shipped ? <p className='min-w-2 h-2 rounded-full bg-green-500'></p> : <p className='min-w-2 h-2 rounded-full bg-orange-500'></p>}
                                        <p style={{ color: theme.text }} className='text-sm md:text-base'>{order.shipped ? 'Item is Shipped' : 'Waiting to be shipped'}</p>
                                    </div>
                                    {order.shipped && order.active ? <button style={{ color: theme.text }} className='btn btn-ghost px-4 py-2 text-sm font-medium'>Track Order</button> : <button style={{ color: theme.text }} disabled className='px-4 py-2 text-sm font-medium'>Track Order</button>}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className='text-gray-400'>You have no active orders yet.</p>
                    )
                }
            </div>
        </div>
    );
};

export default Orders;
