import React, { useContext, useEffect, useState } from 'react';
import Title from '../global/Title';
import { ShopContext } from '../../context/ShopContext';

const CartTotals = () => {
    const { cartItems, deliveryFee, currency } = useContext(ShopContext); // Fetch cartItems and deliveryFee from context

    const [cartSubtotal, setCartSubtotal] = useState(0);

    // Function to calculate the subtotal based on the cart items
    useEffect(() => {
        const calculateCartSubtotal = () => {
            let subtotal = 0;
            cartItems.forEach(item => {
                subtotal += item.price * item.quantity; // Assuming item object has price and quantity fields
            });
            setCartSubtotal(subtotal);
        };

        calculateCartSubtotal();
    }, [cartItems]); // Recalculate when cartItems changes

    return (
        <div className='w-full'>
            <div className='text-2xl'>
                <Title text1={'CART'} text2={'TOTALS'} />
            </div>
            <div className='flex flex-col gap-2 mt-2 text-sm'>
                <div className='flex justify-between'>
                    <p>Subtotal</p>
                    <p>${cartSubtotal.toFixed(2)}</p> {/* Display subtotal */}
                </div>
                <hr />
                <div className='flex justify-between'>
                    <p>Shipping</p>
                    <p>${deliveryFee.toFixed(2)}</p> {/* Display delivery fee */}
                </div>
                <hr />
                <div className='flex justify-between'>
                    <b>Total</b>
                    <b>{currency}{(cartSubtotal + deliveryFee).toFixed(2)}</b> {/* Display total amount */}
                </div>
            </div>
        </div>
    );
};

export default CartTotals;
