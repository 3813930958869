import React, { useContext, useEffect, useState } from 'react';
import { ref, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { doc, deleteDoc } from 'firebase/firestore';
import { storage, db } from '../../../firebase';
import { useTheme } from '../../../context/ThemeContext';

const ProductTable = ({
    products = [],
    selectedProducts = [],
    toggleProductSelection,
    toggleAllProducts,
    currency,
    expanded,
    setExpanded,
    handleViewProduct,
    handleArchiveProduct,
}) => {
    const { theme } = useTheme(); // Get theme colors
    const [imageUrls, setImageUrls] = useState({});
    const [searchQuery, setSearchQuery] = useState(''); // State for search input
    const [filteredProducts, setFilteredProducts] = useState(products); // State for filtered products

    // Fetch the image URLs from Firebase Storage for each product
    useEffect(() => {
        const fetchImages = async () => {
            const urls = {};
            for (const product of products) {
                if (product.id) { // Fetch image even if product is archived
                    const storageRef = ref(storage, `products/${product.id}/images/`);
                    try {
                        const res = await listAll(storageRef);
                        if (res.items.length > 0) {
                            const url = await getDownloadURL(res.items[0]);
                            urls[product.id] = url; // Save the URL for the specific product ID
                        }
                    } catch (error) {
                        console.error(`Error fetching image for product ${product.id}:`, error);
                    }
                }
            }
            setImageUrls(urls);
        };

        if (products.length > 0) {
            fetchImages();
        }
    }, [products]);

    // Search products by name, tags, or colors
    useEffect(() => {
        if (searchQuery.trim()) {
            const lowerCaseQuery = searchQuery.toLowerCase();
            const filtered = products.filter(product =>
                product.name.toLowerCase().includes(lowerCaseQuery) ||
                product.tags.some(tag => tag.toLowerCase().includes(lowerCaseQuery)) ||
                product.colors.some(color => color.toLowerCase().includes(lowerCaseQuery))
            );
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts(products); // Reset to all products if search is cleared
        }
    }, [searchQuery, products]);

    // Delete the entire product folder in Firebase Storage and the product document from Firestore
    const handleDeleteProduct = async (productIds) => {
        try {
            for (const productId of productIds) {
                // Delete all images in the product folder
                const storageRef = ref(storage, `products/${productId}/images/`);
                const listResponse = await listAll(storageRef);
                const deletePromises = listResponse.items.map((itemRef) => deleteObject(itemRef));
                await Promise.all(deletePromises);

                // Delete the product from Firestore
                const productRef = doc(db, 'products', productId);
                await deleteDoc(productRef);
                console.log(`Product ${productId} and its images deleted successfully.`);
            }
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    // Sort the products by archive status and date
    const sortedProducts = [...filteredProducts].sort((a, b) => {
        if (a.archive !== b.archive) {
            return a.archive ? 1 : -1; // Archived products come last
        }
        return new Date(b.listed_at) - new Date(a.listed_at); // Sort by date (newest to oldest)
    });

    return (
        <div className="overflow-x-auto w-full">
            {/* Search bar and Product Count */}
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold" style={{ color: theme.text }}>
                    Product List ({filteredProducts.length} items)
                </h2>
                <input
                    type="text"
                    placeholder="Search products by name, tags, colors..."
                    className="input input-bordered w-full max-w-xs mt-1 mr-1"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{ backgroundColor: theme.secondary, borderColor: theme.border, color: theme.text }}
                />
            </div>

            <div className="overflow-auto max-h-[400px]">
                <table className="table w-full" style={{ backgroundColor: theme.primary, color: theme.text }}>
                    <thead className="sticky top-0 bg-white" style={{ backgroundColor: theme.primary }}>
                        <tr>
                            <th className="w-1/12">
                                <input
                                    type="checkbox"
                                    checked={selectedProducts.length === products.length}
                                    onChange={toggleAllProducts}
                                    className="checkbox"
                                    style={{ backgroundColor: theme.secondary, borderColor: theme.border }}
                                />
                            </th>
                            <th className="w-4/12">Product</th>
                            <th className="w-2/12">Price</th>
                            <th className="w-3/12">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        {sortedProducts.length > 0 ? (
                            sortedProducts.map((product) => (
                                <React.Fragment key={product.id}>
                                    <tr>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedProducts.includes(product.id)}
                                                onChange={() => toggleProductSelection(product.id)}
                                                className="checkbox"
                                            />
                                        </td>
                                        <td className="flex items-center gap-2">
                                            <div className="h-12 w-12">
                                                {imageUrls[product.id] ? (
                                                    <img src={imageUrls[product.id]} alt="product" className="object-cover" />
                                                ) : (
                                                    <div>No Image</div>
                                                )}
                                            </div>
                                            <div className={`text-left ${product.archive ? 'text-red-600' : 'font-bold'}`}>
                                                {product.archive ? 'Archived' : product.name}
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`${product.archive ? 'bg-black bg-opacity-30 sm:px-2 sm:py-5 rounded-md' : ''}`}>
                                                {product.archive ? '-.--' : `${currency}${product.price}`}
                                            </div>
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => setExpanded(expanded.includes(product.id) ? expanded.filter(id => id !== product.id) : [...expanded, product.id])}
                                                className="btn btn-ghost btn-sm"
                                            >
                                                {expanded.includes(product.id) ? 'Collapse' : 'Expand'}
                                            </button>
                                        </td>
                                    </tr>

                                    {expanded.includes(product.id) && (
                                        <tr>
                                            <td colSpan="4">
                                                <div className="flex gap-2">
                                                    <button onClick={() => handleViewProduct(product)} className="btn btn-outline hover:bg-base-300 hover:text-white">View</button>
                                                    <button onClick={() => handleArchiveProduct([product.id])} className="btn btn-outline hover:bg-base-300 hover:text-white">Archive</button>
                                                    <button onClick={() => handleDeleteProduct([product.id])} className="btn btn-outline hover:bg-base-300 hover:text-white">Delete</button>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="text-center">No products found.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className="flex gap-2 mt-4">
                <button className="btn btn-outline hover:bg-base-300 hover:text-white" onClick={() => handleArchiveProduct(selectedProducts)}>
                    Archive {selectedProducts.length} items
                </button>
                <button className="btn btn-outline hover:bg-base-300 hover:text-white" onClick={() => handleDeleteProduct(selectedProducts)}>
                    Delete {selectedProducts.length} items
                </button>
            </div>
        </div>
    );
};

export default ProductTable;
