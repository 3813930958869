import React, { useContext, useState, useEffect } from 'react';
import { ShopContext } from '../../context/ShopContext';
import Title from '../../components/global/Title';
import { IoTrashBin } from "react-icons/io5";
import { useAuth } from '../../context/AuthContext';
import { doc, updateDoc, onSnapshot, arrayRemove } from 'firebase/firestore';
import { db } from '../../firebase';  // Firestore instance
import CartTotals from '../../components/cart/CartTotals';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import { useTheme } from '../../context/ThemeContext'; // Import ThemeContext
import { toast } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import react-toastify styles

const Cart = () => {
    const { cartItems, setCartItems, products, currency } = useContext(ShopContext);
    const [cartData, setCartData] = useState([]);
    const { currentUser } = useAuth();
    const navigate = useNavigate();  // Initialize navigate
    const { theme } = useTheme();  // Get the theme from ThemeContext

    // Set up a real-time listener for the current user's cart
    useEffect(() => {
        if (currentUser) {
            const userRef = doc(db, 'customers', currentUser.uid);

            // Set up real-time listener
            const unsubscribe = onSnapshot(userRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const cartFromFirestore = docSnapshot.data().cart || [];
                    setCartItems(cartFromFirestore);  // Update cartItems state in ShopContext

                    // Update cartData based on products
                    const tempData = cartFromFirestore.map(item => {
                        const product = products.find(prod => prod.id === item.itemId);
                        return {
                            itemId: item.itemId,
                            name: item.name,
                            price: item.price,
                            quantity: item.quantity,
                            image: product ? product.images[0] : null,
                            checkoutInProgress: product ? product.checkoutInProgress : false,
                        };
                    });
                    setCartData(tempData);  // Update local cartData for UI rendering
                }
            });

            // Cleanup the listener when the component is unmounted
            return () => unsubscribe();
        }
    }, [currentUser, products, setCartItems]);

    // Function to handle removing an item from the cart
    const handleRemoveFromCart = async (itemId) => {
        const updatedCart = cartItems.filter(item => item.itemId !== itemId);
        setCartItems(updatedCart);

        // Update the cart in Firestore for the current user
        const userRef = doc(db, 'customers', currentUser.uid);
        await updateDoc(userRef, {
            cart: updatedCart.map(item => ({
                itemId: item.itemId,
                name: item.name,
                price: item.price,
                quantity: item.quantity
            }))
        });

        // Also remove the user's uid from the cart list of the product
        const productRef = doc(db, 'products', itemId);
        await updateDoc(productRef, {
            cart: arrayRemove(currentUser.uid)
        });
    };

    // Handle updating quantity
    const handleQuantityChange = async (itemId, newQuantity) => {
        const updatedCart = cartItems.map(item => {
            if (item.itemId === itemId) {
                return { ...item, quantity: newQuantity };
            }
            return item;
        }).filter(item => item.quantity > 0);  // Filter out items with 0 quantity
        setCartItems(updatedCart);

        // Update the cart in Firestore
        const userRef = doc(db, 'customers', currentUser.uid);
        await updateDoc(userRef, {
            cart: updatedCart.map(item => ({
                itemId: item.itemId,
                name: item.name,
                price: item.price,
                quantity: item.quantity
            }))
        });
    };

    // Function to handle proceeding to checkout
    const handleProceedToCheckout = async () => {
        // Check if any items in the cart have checkoutInProgress: true
        const unavailableItems = cartData.filter(item => item.checkoutInProgress).map(item => item.name);
        if (unavailableItems.length > 0) {
            // Show a toast error if any items are unavailable
            toast.error(`The following items are not available at the moment: ${unavailableItems.join(', ')}`);
            return;
        }

        navigate('/place-order');
    };

    return (
        <div className='border-t pt-14' style={{ backgroundColor: theme.primary, color: theme.text }}>
            <div className='text-2xl mb-3'>
                <Title text1={'YOUR'} text2={'CART'} />
            </div>
            <div>
                {cartData.map((item, index) => (
                    <div key={index} className='py-4 border-t border-b text-gray-300 grid grid-cols-[4fr_0.5fr_0.5fr] sm:grid-cols-[4fr_2fr_0.5fr] items-center gap-4'
                        style={{ borderColor: theme.border }}>
                        <div className='flex items-start gap-6'>
                            {/* Display product image if available */}
                            {item.image ? (
                                <img src={item.image} alt={item.name} className='w-16 sm:w-20' />
                            ) : (
                                <div className="w-16 sm:w-20 bg-gray-200 text-center">No Image</div>
                            )}
                            <div>
                                <p style={{ color: theme.text }} className='text-sm font-medium'>{item.name}</p>
                                <div className='flex items-center gap-5 mt-2'>
                                    <p style={{ color: theme.text }}>{currency}{item.price}</p>
                                </div>
                            </div>
                        </div>
                        <input
                            className='border max-w-10 sm:max-w-20 px-1 sm:px-2 py-1 text-center rounded-full bg-inherit'
                            type='number'
                            min={1}
                            value={item.quantity}
                            onChange={(e) => handleQuantityChange(item.itemId, parseInt(e.target.value))}
                            style={{ borderColor: theme.border, color: theme.text }}
                        />
                        <IoTrashBin onClick={() => handleRemoveFromCart(item.itemId)} className='cursor-pointer' size={28}
                            style={{ color: theme.error }} />
                    </div>
                ))}
            </div>
            <div className='flex justify-end my-20'>
                <div className='w-full sm:w-[450px]'>
                    <CartTotals />
                    <div className='w-full text-end'>
                        <button onClick={handleProceedToCheckout} className='btn my-8 px-8 py-3 text-sm'
                            style={{ backgroundColor: theme.accent, color: theme.alttext }}>PROCEED TO CHECKOUT</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cart;