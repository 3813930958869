import React from 'react';
import Title from '../../components/global/Title';
import logo from '../../assets/logo.png';
import logosquare from '../../assets/logosquare.png';
import { FaInstagram, FaTiktok } from 'react-icons/fa';
import { RiTwitterXFill } from "react-icons/ri";
import Newsletter from '../../components/newsletter/Newsletter';
import { useTheme } from '../../context/ThemeContext'; // Import the theme context

const Contact = () => {
    const { theme } = useTheme(); // Get the current theme
    const openLink = (url) => window.open(url, '_blank');

    return (
        <div style={{ color: theme.text }}>
            <div className='text-center text-2xl pt-10 border-t' style={{ borderColor: theme.border }}>
                <Title text1={'CONTACT'} text2={'US'} />
            </div>
            <div className='my-10 flex flex-col justify-center md:flex-row gap-10 mb-28'>
                {/* Conditional rendering for logo based on theme */}
                <img src={logosquare} alt='' className='w-full md:max-w-[480px] rounded-[80px]' />
                <div className='flex flex-col justify-center items-start gap-6'>
                    <p className='font-semibold text-xl' style={{ color: theme.text }}>Our Store</p>
                    <p style={{ color: theme.text }}>
                        123 Address/PObox <br /> Suite 333, Thomaston, CT
                    </p>
                    <p style={{ color: theme.text }}>
                        Tel: (888) 888-8888 <br /> Email: contact@staplestreetstyles.com
                    </p>
                    <p className='font-semibold text-xl' style={{ color: theme.text }}>Our Socials</p>
                    <div className='flex flex-row gap-4'>
                        <button
                            onClick={() => openLink('https://instagram.com/staplestreetstyles')}
                            className='btn btn-outline'
                            style={{ backgroundColor: theme.secondary, color: theme.text }}
                        >
                            <FaInstagram size={20} className='mr-2' /> Instagram
                        </button>
                        <button
                            onClick={() => openLink('https://x.com/stapleststyles')}
                            className='btn btn-outline'
                            style={{ backgroundColor: theme.secondary, color: theme.text }}
                        >
                            <RiTwitterXFill size={20} className='mr-2' /> X
                        </button>
                        <button
                            onClick={() => openLink('https://tiktok.com/@staplestreetstyles')}
                            className='btn btn-outline'
                            style={{ backgroundColor: theme.secondary, color: theme.text }}
                        >
                            <FaTiktok size={20} className='mr-2' /> TikTok
                        </button>
                    </div>
                </div>
            </div>
            <Newsletter />
        </div>
    );
}

export default Contact;