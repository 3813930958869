import React, { useContext, useEffect, useState } from 'react';
import { ShopContext } from '../../context/ShopContext';
import { ref, getDownloadURL, listAll } from 'firebase/storage';
import { storage } from '../../firebase';
import Title from '../global/Title';
import ProductItem from '../collections/ProductItem';
import { useTheme } from '../../context/ThemeContext'; // Import theme context

const PopularCollection = () => {
    const { products } = useContext(ShopContext);
    const { theme } = useTheme();
    const [popularProducts, setPopularProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state

    useEffect(() => {
        const fetchPopularProducts = async () => {
            try {
                const sortedProducts = products
                    .filter(
                        product =>
                            product.likes &&
                            Array.isArray(product.likes) &&
                            !product.sold &&
                            !product.archive // Filter out sold or archived products (updated to use `archive`)
                    )
                    .sort((a, b) => b.likes.length - a.likes.length) // Sort by number of likes, descending
                    .slice(0, 10);

                const updatedProducts = await Promise.all(
                    sortedProducts.map(async (product) => {
                        const storageRef = ref(storage, `products/${product.id}/images/`);
                        try {
                            const res = await listAll(storageRef);
                            const imageUrls = await Promise.all(
                                res.items.map(item => getDownloadURL(item))
                            );
                            product.images = imageUrls.length > 0 ? imageUrls : ['path/to/default-image.jpg'];
                        } catch (error) {
                            console.error(`Error fetching images for ${product.name}:`, error);
                            product.images = ['path/to/default-image.jpg'];
                        }
                        return product;
                    })
                );

                setPopularProducts(updatedProducts);
            } catch (error) {
                console.error('Error fetching popular products:', error);
                setPopularProducts([]);
            } finally {
                setIsLoading(false); // Always set loading to false after processing
            }
        };

        if (products.length > 0) {
            fetchPopularProducts();
        } else {
            setPopularProducts([]);
            setIsLoading(false); // No products available
        }
    }, [products]);

    return (
        <div className="my-10">
            <div className="text-center py-8 text-3xl">
                <Title text1={'MOST'} text2={'POPULAR'} />
                <p
                    className="w-3/3 m-auto text-xs sm:text-sm md:text-base"
                    style={{ color: theme.text }}
                >
                    Shop our most sought after pieces
                </p>
            </div>
            {isLoading ? (
                <div className="text-center text-gray-500">Loading...</div>
            ) : popularProducts.length > 0 ? (
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 gap-y-6">
                    {popularProducts.map((item, index) => (
                        <ProductItem
                            key={index}
                            id={item.id}
                            image={item.images[0]}
                            name={item.name}
                            price={item.price}
                            archived={item.archive}
                        />
                    ))}
                </div>
            ) : (
                <div className="text-center text-gray-500">No popular products found.</div>
            )}
        </div>
    );
};

export default PopularCollection;
