import React, { useState } from 'react';
import { FiUser, FiSettings } from 'react-icons/fi';
import PreferencesSettings from './PreferencesSettings';
import ProfileSettings from './ProfileSettings'; // Assume ProfileSettings is properly set up
import { useTheme } from '../../context/ThemeContext'; // Import useTheme hook

const Settings = () => {
    const [activeTab, setActiveTab] = useState('preferences');
    const { theme } = useTheme(); // Get the current theme

    return (
        <div
            className="min-h-screen p-6"
            style={{ backgroundColor: theme.primary, color: theme.text }} // Apply theme using inline styles
        >
            <div className="tabs flex justify-center space-x-4 mb-8">
                <button
                    className={`tab tab-lifted ${activeTab === 'preferences' ? 'tab-active' : ''}`}
                    onClick={() => setActiveTab('preferences')}
                    style={{
                        backgroundColor: activeTab === 'preferences' ? theme.secondary : '',
                        color: activeTab === 'preferences' ? theme.text : '',
                    }}
                >
                    <FiSettings className="inline-block mr-2" /> Preferences
                </button>
                <button
                    className={`tab tab-lifted ${activeTab === 'profile' ? 'tab-active' : ''}`}
                    onClick={() => setActiveTab('profile')}
                    style={{
                        backgroundColor: activeTab === 'profile' ? theme.secondary : '',
                        color: activeTab === 'profile' ? theme.text : '',
                    }}
                >
                    <FiUser className="inline-block mr-2" /> Profile
                </button>
            </div>

            <div className="container mx-auto">
                {activeTab === 'preferences' ? <PreferencesSettings /> : <ProfileSettings />}
            </div>
        </div>
    );
};

export default Settings;
