import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useTheme } from '../../context/ThemeContext';
import { FiSearch } from 'react-icons/fi'; // Search icon
import CurrentOrder from './CurrentOrder';

const AdminOrders = () => {
    const { theme } = useTheme(); // Access custom theme
    const db = getFirestore();

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOrders, setSelectedOrders] = useState([]); // Selected orders for batch actions
    const [revealedOrderIds, setRevealedOrderIds] = useState({});
    const [searchQuery, setSearchQuery] = useState(''); // Search input state
    const [filteredOrders, setFilteredOrders] = useState([]); // State to store filtered orders
    const [activeOrder, setActiveOrder] = useState(null); // State to store the active order for viewing

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const ordersPerPage = window.innerWidth > 768 ? 5 : 3; // 5 per page on large screens, 3 on small screens

    // Function to fetch customer information based on customerId
    const fetchCustomerInfo = async (customerId) => {
        try {
            const customerRef = doc(db, 'customers', customerId);
            const customerSnap = await getDoc(customerRef);
            if (customerSnap.exists()) {
                const customerData = customerSnap.data();
                return {
                    displayName: customerData.firstName + ' ' + customerData.lastName,
                    email: customerData.email,
                };
            } else {
                console.warn(`Customer with ID ${customerId} not found.`);
                return {
                    displayName: 'Unknown',
                    email: 'Unknown',
                };
            }
        } catch (error) {
            console.error(`Error fetching customer info for ID ${customerId}:`, error);
            return {
                displayName: 'Unknown',
                email: 'Unknown',
            };
        }
    };

    // Function to fetch product information based on productId
    const fetchProductInfo = async (productId) => {
        try {
            const productRef = doc(db, 'products', productId);
            const productSnap = await getDoc(productRef);
            if (productSnap.exists()) {
                const productData = productSnap.data();
                const firstImage = productData.images && productData.images[0] ? productData.images[0].url : "/placeholder.png";
                return {
                    name: productData.name || "Unnamed Product",
                    imageUrl: firstImage,
                    price: productData.price || "N/A",
                };
            } else {
                console.warn(`Product with ID ${productId} not found.`);
                return {
                    name: "Product not found",
                    imageUrl: "/placeholder.png",
                    price: "N/A",
                };
            }
        } catch (error) {
            console.error(`Error fetching product info for ID ${productId}:`, error);
            return {
                name: "Error fetching product",
                imageUrl: "/placeholder.png",
                price: "N/A",
            };
        }
    };

    // Fetch orders from Firebase and match customer and product information
    useEffect(() => {
        const fetchAllOrders = async () => {
            try {
                const ordersCollection = collection(db, 'orders');
                const orderSnapshot = await getDocs(ordersCollection);
                const ordersWithDetails = await Promise.all(
                    orderSnapshot.docs.map(async (orderDoc) => {
                        const orderData = orderDoc.data();
                        const customerInfo = await fetchCustomerInfo(orderData.customerId);
                        const productInfo = await fetchProductInfo(orderData.productId);
                        return {
                            id: orderDoc.id,
                            ...orderData,
                            customerName: customerInfo.displayName,
                            customerEmail: customerInfo.email,
                            productName: productInfo.name,
                            productImage: productInfo.imageUrl,
                            productPrice: productInfo.price,
                        };
                    })
                );
                setOrders(ordersWithDetails);
                setFilteredOrders(ordersWithDetails); // Initialize filteredOrders with full orders
                setLoading(false);
            } catch (error) {
                console.error('Error fetching orders:', error);
                setLoading(false);
            }
        };
        fetchAllOrders();
    }, [db]);

    // Function to copy order ID to clipboard
    const copyToClipboard = (orderId) => {
        navigator.clipboard.writeText(orderId)
            .then(() => {
                console.log('Order ID copied to clipboard:', orderId);
            })
            .catch(err => {
                console.error('Could not copy order ID to clipboard: ', err);
            });
    };

    // Function to handle order toggle
    const handleOrderToggle = (order) => {
        setActiveOrder(order);
        console.log('Viewing order:', order.id);
    };

    // Function to toggle the reveal state for an order
    const toggleRevealOrderId = (orderId) => {
        setRevealedOrderIds(prevState => ({
            ...prevState,
            [orderId]: !prevState[orderId],
        }));
        copyToClipboard(orderId); // Copy to clipboard upon clicking
    };

    // Toggle shipped status for an order
    const toggleShipped = async (id, isShipped) => {
        try {
            const orderRef = doc(db, 'orders', id);
            await updateDoc(orderRef, { shipped: !isShipped });
            setOrders(prevOrders =>
                prevOrders.map(order =>
                    order.id === id ? { ...order, shipped: !isShipped } : order
                )
            );
        } catch (error) {
            console.error("Error updating shipped status:", error);
        }
    };

    // Toggle order selection
    const toggleOrderSelection = (id) => {
        if (selectedOrders.includes(id)) {
            setSelectedOrders(selectedOrders.filter(orderId => orderId !== id));
        } else {
            setSelectedOrders([...selectedOrders, id]);
        }
    };

    // Toggle select all orders
    const toggleSelectAll = () => {
        if (selectedOrders.length === filteredOrders.length) {
            setSelectedOrders([]);
        } else {
            setSelectedOrders(filteredOrders.map(order => order.id));
        }
    };

    // Search functionality
    useEffect(() => {
        if (searchQuery.trim()) {
            const lowerCaseQuery = searchQuery.toLowerCase();
            const filtered = orders.filter(order =>
                order.productId.toLowerCase().includes(lowerCaseQuery) ||
                order.customerId.toLowerCase().includes(lowerCaseQuery) ||
                order.id.toLowerCase().includes(lowerCaseQuery) ||
                order.customerName.toLowerCase().includes(lowerCaseQuery) ||
                order.customerEmail.toLowerCase().includes(lowerCaseQuery)
            );
            setFilteredOrders(filtered);
        } else {
            setFilteredOrders(orders);
        }
    }, [searchQuery, orders]);

    // Pagination logic
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

    const nextPage = () => {
        if (indexOfLastOrder < filteredOrders.length) setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        if (indexOfFirstOrder > 0) setCurrentPage(currentPage - 1);
    };

    if (loading) {
        return (
            <div className='flex justify-center'>
                <span className="loading loading-spinner loading-lg"></span>
            </div>
        );
    }

    return (
        <div className="overflow-x-auto">
            {/* Search bar and Title */}
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold" style={{ color: theme.text }}>Admin Orders Management</h2>
                <div className="relative max-w-xs w-full">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <FiSearch style={{ color: theme.text }} />
                    </span>
                    <input
                        type="text"
                        placeholder="Search by Order ID, etc..."
                        className="block w-full pl-10 pr-4 py-2 rounded-lg focus:outline-none"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{ backgroundColor: theme.secondary, color: theme.text }}
                    />
                </div>
            </div>
            {/* Table */}
            <table className="table w-full" style={{ backgroundColor: theme.primary, color: theme.text }}>
                {/* Table Head */}
                <thead>
                    <tr style={{ backgroundColor: theme.secondary, color: theme.text }}>
                        <th className="sm:w-16 md:w-24 lg:w-32">
                            <label>
                                <input type="checkbox" className="checkbox" checked={selectedOrders.length === filteredOrders.length} onChange={toggleSelectAll} />
                            </label>
                        </th>
                        <th>Order ID</th>
                        <th>Customer</th>
                        <th>Product</th>
                        <th>Status</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                </thead>
                {/* Table Body */}
                <tbody>
                    {currentOrders.map(order => (
                        <tr key={order.id} style={{ color: theme.text }}>
                            <th>
                                <label>
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        checked={selectedOrders.includes(order.id)}
                                        onChange={() => toggleOrderSelection(order.id)}
                                    />
                                </label>
                            </th>
                            <td>
                                <button
                                    className="btn btn-xs"
                                    style={{
                                        color: theme.accent,
                                        transition: 'all 0.3s ease-in-out',
                                    }}
                                    onClick={() => toggleRevealOrderId(order.id)}
                                >
                                    {revealedOrderIds[order.id] ? order.id : 'View ID'}
                                </button>
                            </td>
                            <td>
                                <div className="flex items-center gap-3">
                                    <div>
                                        <div className="font-bold">{order.customerName || 'Unknown'}</div>
                                        <div className="text-sm opacity-50">{order.customerEmail || 'Unknown'}</div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="flex items-center gap-3">
                                    <img
                                        src={order.productImage || "https://via.placeholder.com/150"}
                                        alt={order.productName || "Unknown"}
                                        className="h-12 w-12"
                                    />
                                    <div>
                                        <div className="font-bold">{order.productName || 'Unknown Product'}</div>
                                        <div className="text-sm opacity-50">{order.productId}</div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <button
                                    className={`btn btn-xs ${order.shipped ? 'btn-primary' : 'btn-secondary'}`}
                                    onClick={() => toggleShipped(order.id, order.shipped)}
                                    style={{ backgroundColor: order.shipped ? theme.success : theme.error, color: theme.alttext }}
                                >
                                    {order.shipped ? 'Shipped' : 'Pending'}
                                </button>
                            </td>
                            <td>${order.productPrice !== undefined && order.productPrice !== null ? `${order.productPrice}` : 'N/A'}</td>
                            <td>
                                <button onClick={() => handleOrderToggle(order)} className="btn btn-ghost btn-xs" style={{ color: theme.accent }}>
                                    Details
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Pagination Controls */}
            <div className="flex justify-between mt-4">
                <button onClick={prevPage} disabled={indexOfFirstOrder <= 0} className="btn btn-primary">
                    Previous
                </button>
                <button onClick={nextPage} disabled={indexOfLastOrder >= filteredOrders.length} className="btn btn-primary">
                    Next
                </button>
            </div>
            {/* Active Order Modal */}
            {
                activeOrder && (
                    <>
                        {console.log('loading')}
                        <CurrentOrder
                            order={activeOrder}
                            setActiveOrder={setActiveOrder}
                        />
                    </>
                )
            }

        </div >
    );
};

export default AdminOrders;