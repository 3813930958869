import React from 'react';
import { useTheme } from '../../context/ThemeContext'; // Use theme

const Hero = () => {
    const { theme } = useTheme();

    return (
        <div className='border-t' style={{ borderColor: theme.border }}>
            {/* Hero Section */}
            <div className='flex flex-col sm:flex-row mt-2 border' style={{ borderColor: theme.border }}>
                {/* Hero Left Side */}
                <div className='w-full sm:w-1/2 flex items-center justify-center py-10 sm:py-0'>
                    <div style={{ color: theme.text }}>
                        <div className='flex items-center gap-2'>
                            <p className='w-8 md:w-11 h-[2px]' style={{ backgroundColor: theme.accent }}></p>
                            <p className='font-medium text-sm md:text-base'>OUR BESTSELLERS</p>
                        </div>
                        <h1 className='prata-regular text-3xl sm:py-3 lg:text-5xl leading-relaxed'>Latest Arrivals</h1>
                        <div className='flex items-center gap-2'>
                            <p className='font-semibold text-sm md:text-base'>SHOP NOW</p>
                            <p className='w-8 md:w-11 h-[1px]' style={{ backgroundColor: theme.accent }}></p>
                        </div>
                    </div>
                </div>
                {/* Hero Right Side */}
                <img src='https://media.istockphoto.com/id/1018293976/photo/attractive-fashionable-woman-posing-in-white-trendy-sweater-beige-pants-and-autumn-heels-on.jpg?s=612x612&w=0&k=20&c=_CLawpZw6l9z0uV4Uon-7lqaS013E853ub883pkIK3c=' alt='hero' className='w-full sm:w-1/2 object-cover object-center' />
            </div>
        </div>
    );
};

export default Hero;
