import React, { useEffect, useState } from 'react';

const AddProductForm = ({
    newProduct,
    setNewProduct,
    handleInputChange,
    handleAddProduct,
    handleEditProduct,
    isEditMode,
    handleSwitchToAddMode // Add this prop to handle switching back to Add Product mode
}) => {
    const [editMode, setEditMode] = useState(isEditMode);

    useEffect(() => {
        setEditMode(isEditMode);
    }, [isEditMode, newProduct]);

    return (
        <div className="form-control w-full max-w-xs mb-4 gap-2">
            <label className="label text-xl font-bold">
                {editMode ? 'Edit Product' : 'Add Product'}
            </label>

            {/* Product Name and Price */}
            <div className="flex flex-col sm:flex-row gap-2">
                <input
                    type="text"
                    placeholder="Product Name"
                    name="name"
                    value={newProduct.name || ''}
                    onChange={handleInputChange}
                    className="input input-bordered w-full max-w-xs"
                />
                <input
                    type="number"
                    placeholder="0.00"
                    name="price"
                    value={newProduct.price || ''}
                    onChange={handleInputChange}
                    className="input input-bordered w-full max-w-xs"
                />
            </div>

            {/* Category and Subcategory */}
            <div className="flex flex-col sm:flex-row gap-2">
                <select
                    className="select select-bordered w-full max-w-xs"
                    name="category"
                    value={newProduct.category || ''} // Ensure value is properly set
                    onChange={handleInputChange}
                >
                    <option value="" disabled>Main Category</option> {/* Set disabled option */}
                    <option value="men">Men</option>
                    <option value="women">Women</option>
                    <option value="children">Children</option>
                    <option value="extra">Extra</option>
                </select>

                <select
                    className="select select-bordered w-full max-w-xs"
                    name="subCategory"
                    value={newProduct.subCategory || ''} // Ensure value is properly set
                    onChange={handleInputChange}
                >
                    <option value="" disabled>Sub Category</option> {/* Set disabled option */}
                    <option value="tops">Tops</option>
                    <option value="bottoms">Bottoms</option>
                    <option value="hats">Hats</option>
                    <option value="accessories">Accessories</option>
                    <option value="purses">Purses</option>
                    <option value="shoes">Shoes</option>
                    <hr class="border-t-2 border-white my-4" />
                    <option value="bathandbody">Bath & Body</option>
                </select>
            </div>

            {/* Condition, Size, and Weight */}
            <div className='flex flex-col gap-2'>
                <select
                    name="weight"
                    value={newProduct.weight || ''}
                    onChange={handleInputChange}
                    className="select select-bordered w-full max-w-xs"
                >
                    <option value="" disabled>Weight</option> {/* Set disabled option */}
                    <option value="XXS">XXS: (Under 4oz - Jewelry, small accessories)</option>
                    <option value="XS">XS: (Under 8oz - Swimwear, small tops, small purses)</option>
                    <option value="S">S: (Under 12oz - Tops, t-shirts, pants)</option>
                    <option value="M">M: (Under 1lb - Jeans, lightweight jumpers)</option>
                    <option value="L">L: (Under 2lb - Hoodies, light jackets, sneakers)</option>
                    <option value="XL">XL: (Under 10lb - Bundles, heavy boots)</option>
                </select>
                <div className="flex flex-col sm:flex-row gap-2">
                    <input
                        className="input input-bordered w-full max-w-xs"
                        name="size"
                        value={newProduct.size || ''}
                        onChange={handleInputChange}
                    />
                    <select
                        className="select select-bordered w-full max-w-xs"
                        name="condition"
                        value={newProduct.condition || ''} // Ensure value is properly set
                        onChange={handleInputChange}
                    >
                        <option value="" disabled>Condition</option> {/* Set disabled option */}
                        <option value="new">New</option>
                        <option value="excellent">Excellent</option>
                        <option value="good">Good</option>
                        <option value="fair">Fair</option>
                        <option value="poor">Poor</option>
                    </select>
                </div>
            </div>

            {/* Product Description */}
            <textarea
                placeholder="Product Description"
                name="description"
                value={newProduct.description || ''}
                onChange={handleInputChange}
                className="textarea textarea-bordered w-full max-w-xs"
            ></textarea>

            {/* Submit Button */}
            <div className="flex flex-col gap-2">
                <button
                    className={`btn ${editMode ? 'btn-warning' : 'btn-primary'} w-full mt-4`}
                    onClick={editMode ? handleEditProduct : handleAddProduct}
                >
                    {editMode ? 'Apply Updates' : 'Add Product'}
                </button>

                {editMode && (
                    <button
                        className="btn btn-outline btn-error w-full"
                        onClick={handleSwitchToAddMode} // Switch to Add Product mode
                    >
                        Switch to Add Product
                    </button>
                )}
            </div>
        </div>
    );
};

export default AddProductForm;